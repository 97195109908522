import React, { useRef, useEffect, useState } from "react";
import { TomorrowBox } from "./TomorrowBox";

function Scroller(props: { text: string; amount: number }) {
  const amount = Math.max(0, Math.min(1, props.amount));
  return <>{props.text.slice(0, amount * props.text.length)}</>;
}

export function Day17() {
  const musicRef = useRef<HTMLAudioElement | null>(null);
  const [time, setTime] = useState(0);
  useEffect(() => {
    const music = document.createElement("audio");
    music.src = "sintra.mp3";
    musicRef.current = music;

    function loop() {
      requestAnimationFrame(loop);
      setTime(music.currentTime);
    }
    requestAnimationFrame(loop);
  }, []);

  return (
    <div className="day-wrapper day-17">
      <div className="content-card" style={{ marginTop: "32px" }}>
        <h2>Kapittel 17</h2>
        <p>
          Det nærmer seg midnatt i det motorsykkelen svinger inn på en stor
          gårdsplass. Dere har kjørt i timesvis, og veiene har blitt gradvis
          smalere og smalere. Eller, det er i alle fall det du antar. Det ble
          mørkt halvveis inn i turen, og etter det har du ikke egentlig kunnet
          se mye. Gatelys var det heller lite av. Sjåføren parkerer midt på
          gårdsplassen og skrur av motoren. Visiren på hjelmen din åpner seg
          brått. Sjåføren peker opp mot et stort gammelt steinbygg på enden av
          plassen. Kanskje det eneste bygget i mils omkrets?
        </p>

        <p style={{ color: "gold", textShadow: "1px 1px 0 black" }}>
          &laquo;Él está esperando.&raquo;
        </p>

        <p>
          Du hopper av motorsykkelen. Du merker det ikke før nå, men det regner
          tungt. I det du kommer deg av og på bena snur motorsykkelen seg bak
          deg. Motoren ruses, og sjåføren piler avgårde inn i mørket som dere
          kom fra. Du står igjen helt alene på gårdsplassen. Det eneste lyset
          kommer fra en liten lampe over inngangsdøren.
        </p>

        {time === 0 && (
          <>
            <button
              className="btn btn-primary"
              style={{ margin: "64px auto 32px", display: "block" }}
              onClick={e => {
                e.preventDefault();
                musicRef.current && musicRef.current.play();
              }}
            >
              Bank på
            </button>
            <div className="alert alert-info">🔊 Skru på lyd!</div>
          </>
        )}

        {time > 0 && (
          <>
            <hr style={{ margin: "64px 0" }} />
            <p>
              <Scroller
                text="Det er en stor og gammel dør i brunt tre. Midt på døra sitter en tung, ornamentell metallring."
                amount={(time - 1) / 7}
              />{" "}
              <Scroller
                text="Du tar et steg nærmere og griper forsiktig, men bestemt tak."
                amount={(time - 10) / 3}
              />
            </p>
            <p>
              <em>
                <Scroller text="Bank." amount={(time - 14.54) * 1000} />{" "}
                <Scroller text="Bank." amount={(time - 14.98) * 1000} />{" "}
                <Scroller text="Bank." amount={(time - 15.42) * 1000} />
              </em>
            </p>

            <p>
              <Scroller
                text="Du slår hardt, men regnet pisker hardere."
                amount={(time - 17) / 3}
              />{" "}
            </p>
            <p>
              <Scroller text="Ingen svar." amount={(time - 22) / 5} />{" "}
            </p>

            <p>
              <em>
                <Scroller text="Bank." amount={(time - 32.42) * 1000} />{" "}
                <Scroller text="Bank." amount={(time - 32.83) * 1000} />{" "}
                <Scroller text="Bank." amount={(time - 33.25) * 1000} />
              </em>
            </p>
            <p>
              <Scroller
                text="Er det fottrinn du hører?"
                amount={(time - 41) / 2}
              />{" "}
              <Scroller
                text="Du merker plutselig at du holder pusten."
                amount={(time - 45) / 3}
              />{" "}
            </p>
            <p>
              <Scroller text="Det rasler i låsene." amount={(time - 52) / 2} />{" "}
              <Scroller text="Døren går opp." amount={(time - 56) / 3} />{" "}
            </p>
            <p>
              <Scroller
                text="I døråpningen står en høy, værbitt mann med langt skjegg og hvite hansker. Øynene er nesten begravd under tykke, bustete, hvite øyenbryn."
                amount={(time - 63) / 7}
              />{" "}
              <Scroller
                text="Han strekker ut hånden og introduserer seg selv."
                amount={(time - 72) / 5}
              />{" "}
            </p>

            <p
              style={{
                marginBottom: "32px",
                fontSize: "32px",
                textAlign: "center"
              }}
            >
              <em>
                <Scroller
                  text="&laquo;Sintra.&raquo;"
                  amount={(time - 60 - 21) / 1}
                />{" "}
              </em>
            </p>
          </>
        )}
      </div>

      {time > 90 && (
        <TomorrowBox
          questions={[
            "OOOOOOoooooooooooo",
            "Hva skjer nå?!",
            "OOoooOOOoooooooOOOoooooooooooooo!"
          ]}
        />
      )}
    </div>
  );
}
