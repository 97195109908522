import React from "react";
import { TomorrowBox } from "./TomorrowBox";

export function Day20() {
  return (
    <div className="day-wrapper day-20">
      <div className="content-card" style={{ marginTop: "32px" }}>
        <h2>Kapittel 20</h2>
        <p>
          Det har blitt kveld, og det er god stemning inne på The Highbury Pub.
          Bartender og eier Grythe leverer rask og effektiv service til de som
          har behov for hennes tjenester, og det er mange av dem. Det er hit
          Sinterklaas har tatt deg med. Det er nesten stappfullt, og det var
          vanskelig å finne plass. Derfor sitter dere klemt sammen i baren.
          Lydnivået er jevnt over høyt. Promillenivået kanskje enda høyere.
        </p>
        <p>
          Politimester Jeneus kommer inn døra. Med ett blir det stille i
          lokalet. Politimesteren inne på The Highbury Pub? Høyst uvanlig.
          Balansen mellom Oslos undergrunn og kommunens tjenestemenn er hårfin,
          og møteplassen er The Highbury Pub. Men det har aldri vært
          politimesteren, kun detektiver, som har vist seg. Dette er noe høyst
          uvanlig. Alle øyne er rettet mot politimesteren. Det er en elektrisk
          spenning i lokalet. Et feil ord nå, så blir det en ordentlig
          slosskamp, tenker du.
        </p>
        <p>&laquo;Jeg spanderer neste runde!&raquo; roper politimesteren.</p>

        <p>
          Det er stille i ett sekund. Og så ett sekund til. Endelig er det en
          enslig stemme fra inne i kroken som roper &laquo;Woooo!&raquo;. Puben
          bryter ut i en kort jubel, og med ett er ting tilbake til den bråkete
          normalen. Phew, tenker du. Politimesteren åler seg bort til baren der
          dere sitter.
        </p>

        <p>&laquo;Vest. Sintra.&raquo;</p>
        <p>&laquo;Jeneus.&raquo;</p>
        <p>&laquo;Politimester.&raquo;</p>

        <p>Dere hilser hverandre.</p>

        <p>
          &laquo;Troppene er altså samlet. Som har sett, har Santa Claus fått
          tilbake nisselua si, og dermed også fått tilbake kontrollen over
          nissekreftene sine. Jeg tror han planlegger å fortsette arbeidet som
          han startet på den gangen for mange år siden. Det kan vi ikke la
          skje!&raquo;, sier politimesteren.
        </p>

        <p>&laquo;Hva slags arbeid var det?&raquo;, spør du.</p>

        <p>
          &laquo;For mange år siden var Sintra, Canasta og jeg et team som
          jobbet sammen for å levere gaver til alle snille barn i rundt om i
          hele verden. Vi hadde delt kloden i mellom oss slik at vi skulle rekke
          å få levert ut alt i løpet av en kveld. Canasta hadde i utgangspunktet
          amerikaene og Australia. Sintra og jeg delte Europa, Afrika og Asia i
          mellom oss. Etterhvert begynte Canasta å prøve å dekke et større og
          større areal også, og jobbet på ett vis globalt. Mye av arbeidet i
          løpet av året gikk på kartlegging av hvem som var snille nok til å
          motta gaver. Vi hadde et bra system som varte i mange år, men mot
          slutten begynte Canasta å bli mer radikal i kartleggingen. Han begynte
          å bli mye strengere på hva som måtte til som skulle regnes som snill.
          Han mente at nesten ingen fortjente å få gaver. Den minste infraksjon
          etter hans moral-etiske retningslinjer var nok, og de retningslinjene
          begynte også å bli strengere og mer dogmatiske.&raquo;
        </p>
        <p>
          Politimesteren tar seg en slurk av ølen han hadde fått fra Grythe i
          det han ankom baren. Han lener seg inn og fortsetter med en mørkere
          stemme og i en alvorlig tone:
        </p>
        <p>
          &laquo;Han ville begynne å dele ut straff også. Og vi snakker ikke
          bare kull i julestrømpen eller noe sånt. Vi snakker hardcore shit.
          Brannstiftelser. Kidnappinger. Tortur. Befolkningen stod i fare. Noe
          måtte gjøres.&raquo;
        </p>

        <p>
          Så det var derfor Sintra og politimesteren bestemte seg for å stjele
          den magiske nisselua til Canasta. Nå har han derimot fått tilbake
          kreftene sine. Og han planlegger å terrorisere hele kloden med sitt
          ekstremistiske regime!
        </p>
      </div>

      <TomorrowBox
        questions={[
          "Hvordan skal våre helter få stoppet Santa Claus i tide?",
          "Hvordan er politimesteren på lag med nisser, forresten?",
          "Hvem er det som har delt ut gaver i stedet for Santa Claus? (Kanskje det er derfor noen familier må ty til å dele ut gaver selv og late som at de er fra nissen i stedet for å faktisk få ekte nissegaver?)"
        ]}
      />
    </div>
  );
}
