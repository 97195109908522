import React, { useContext } from "react";
import { Store } from "./App";

interface Props {
  day: string;
  choices: string[];
}

export function ChoiceForm(props: Props) {
  const store = useContext(Store);

  return (
    <div className="choose-selector">
      {props.choices.map((choice, i) => (
        <div
          className={`choice ${
            i === (store.choices && +store.choices[props.day]) ? "active" : ""
          }`}
        >
          <div className="custom-control custom-radio">
            <input
              type="radio"
              id={`choice-day-${props.day}-${i}`}
              name={`choice-day-${props.day}`}
              className="custom-control-input"
              disabled={store.choices && store.choices[props.day] !== undefined}
              onChange={e => {
                e.preventDefault();
                if (e.target.checked) {
                  store.select(props.day, i);
                }
              }}
            />
            <label
              className="custom-control-label"
              htmlFor={`choice-day-${props.day}-${i}`}
            >
              {choice}
            </label>
          </div>
        </div>
      ))}
    </div>
  );
}
