import React, { CSSProperties } from "react";

interface Props {
  questions: string[];
  style?: CSSProperties;
}

export function TomorrowBox(props: Props) {
  return (
    <div className="content-card" style={props.style}>
      <div
        style={{
          fontWeight: "bold",
          marginBottom: "48px",
          textAlign: "center",
          marginTop: "-32px"
        }}
      >
        {props.questions.map(question => (
          <p>{question}</p>
        ))}
      </div>

      <div
        style={{
          textAlign: "center",
          marginBottom: "16px"
        }}
      >
        <div
          className="tagline"
          style={{
            display: "inline-block",
            margin: "0 auto"
          }}
        >
          Følg med i morgen!
        </div>
      </div>
    </div>
  );
}
