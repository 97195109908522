import React, { useState, useEffect, useContext } from "react";
import { TomorrowBox } from "./TomorrowBox";
import { Store } from "./App";
import { DotBreak } from "./DotBreak";

function AnagramSolver(props: {
  start: string;
  end: string;
  onChange: (guess: string, taken: string, isSolved: boolean) => void;
  initialGuess: string;
  initialTaken: string;
}) {
  const [guess, setGuess] = useState(props.initialGuess || "");
  const [taken, setTaken] = useState(props.initialTaken || props.start);
  useEffect(() => {
    props.onChange(guess, taken, guess === props.end);
  }, [guess, props, taken]);
  return (
    <div>
      <p>Aksel Sintra er:</p>
      <div
        style={{
          display: "flex",
          marginTop: "32px",
          marginBottom: "16px"
        }}
      >
        {props.start.split("").map((letter, i) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "64px",
              height: "64px",
              fontSize: "24px",
              borderRadius: "4px",
              fontWeight: "bold",
              fontFamily: "Patrick Hand SC",
              marginRight: "8px",
              background: taken[i] === "_" ? "#eee" : "#aee371",
              color: taken[i] === "_" ? "#ccc" : "white",
              textShadow:
                taken[i] === "_" ? "2px 2px 0 #aaa" : "2px 2px 0 black",
              boxShadow:
                taken[i] === "_" ? "4px 4px 0 #ddd" : "4px 4px 0px #75a142",
              cursor: "pointer"
            }}
            onClick={e => {
              e.preventDefault();
              if (taken[i] === "_") {
                return;
              }
              setTaken(taken.slice(0, i) + "_" + taken.slice(i + 1));
              let guessIndex = guess
                .split("")
                .findIndex(letter => letter === " ");
              if (guessIndex === -1) {
                guessIndex = guess.length;
              }
              setGuess(
                guess.slice(0, guessIndex) +
                  props.start[i] +
                  guess.slice(guessIndex + 1)
              );
            }}
          >
            {letter}
          </div>
        ))}
      </div>

      <div
        style={{
          display: "flex"
        }}
      >
        {props.start.split("").map((_, i) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "64px",
              height: "64px",
              fontSize: "24px",
              borderRadius: "4px",
              fontWeight: "bold",
              fontFamily: "Patrick Hand SC",
              marginRight: "8px",
              background:
                guess[i] === props.end[i]
                  ? "#ffc800"
                  : (guess[i] || "").trim()
                  ? "#888"
                  : "#eee",
              color: guess[i] === props.end[i] ? "black" : "white",
              textShadow:
                guess[i] === props.end[i]
                  ? "2px 2px 0 #ffeeb3"
                  : "2px 2px black",
              boxShadow:
                "4px 4px 0px " +
                (guess[i] === props.end[i]
                  ? "#b59319"
                  : (guess[i] || "").trim()
                  ? "#666"
                  : "#ddd"),
              cursor: "pointer"
            }}
            onClick={e => {
              e.preventDefault();
              if (props.end === guess) {
                return;
              }
              const originalIndex = props.start
                .split("")
                .findIndex(
                  (letter, j) => letter === guess[i] && taken[j] === "_"
                );
              setTaken(
                taken.slice(0, originalIndex) +
                  guess[i] +
                  taken.slice(originalIndex + 1)
              );
              setGuess(guess.slice(0, i) + " " + guess.slice(i + 1));
            }}
          >
            {guess[i]}
          </div>
        ))}
      </div>
    </div>
  );
}

export function Day19() {
  const store = useContext(Store);
  const [isSolved, setIsSolved] = useState(false);
  return (
    <div className="day-wrapper day-19">
      <div className="content-card">
        <h2>Kapittel 19</h2>

        {store.choices && (
          <AnagramSolver
            start="AKSELSINTRA"
            end="SINTERKLAAS"
            initialGuess={(store.choices["19"] || "|").split("|")[0]}
            initialTaken={(store.choices["19"] || "|").split("|")[1]}
            onChange={(guess, initialTaken, newIsSolved) => {
              setIsSolved(newIsSolved);
              store.select("19", guess + "|" + initialTaken);
            }}
          />
        )}

        {isSolved && (
          <>
            <p>Sinterklaas!</p>

            <p>
              Brikkene begynner å falle på plass. Sinterklaas og Santa Claus
              jobbet sammen (med politmester Jeneus også) i gamle dager.
              Etterhvert oppstod det en konflikt mellom Santa Claus og de andre
              to. De to må klart å få tak i den magiske nisselua til Santa Claus
              og låst det vekk i Valkyriegata 13. Uten nisselua kan man bare
              anta at Santa Claus ikke får brukt sine magiske julekrefter. Slik
              må status quo ha vært oppretthodt i mange år (hvor gammel er disse
              nissene egentlig?).
            </p>

            <p>
              Santa Claus kan jo umulig ha likt dette. Han har klekket en plan,
              antakeligvis over lengere tid, for å få tilbake nisselua si og
              igjen kunne kontrollere sine egne nissekrefter. Det var denne
              planen som forårsaket innbruddet i Valkyriegata 13, og satte i
              gang hele denne julekalenderen.
            </p>

            <p>
              Det er åpenbart noe stort på spill her, men du er fortsatt ikke
              helt sikker på akkurat hva. Hva var det Santa Claus ville gjøre
              som gjorde at de andre to sa stopp? Du bestemmer deg for å prøve å
              nøste opp i dette. Hvem vet, kanskje det er Sinterklaas som er den
              slemme, og ikke Santa Claus? Det er best å samle begge to, og
              snakke med de sammen, tenker du.
            </p>

            <p>
              Du forteller alt som har hendt til Sinterklaas. Mye vet han
              allerede. Han sier dere må finne politimesteren for å kunne ha en
              sjanse mot Santa Claus. Sist du så ham var i Santa Claus sitt
              hemmelige underjordiske kontor. Etter det ble du og politimesteren
              separert. Du ble teleportert tilbake til din egen leilighet. Hvem
              vet hva som skjedde med politimesteren? Ett sted må dere begynne å
              lete i alle fall. Dere reiser tilbake til Oslo.
            </p>
          </>
        )}
      </div>

      {isSolved && (
        <TomorrowBox
          questions={[
            "Daaamn sick reveal (nummer 2), bro!",
            "Finner dere politimesteren?",
            "Hva har en skarve politmester noe med selveste Santa Claus og Sinterklaas å gjøre?"
          ]}
        />
      )}
    </div>
  );
}
