import React from "react";
import { TextInputForm } from "./TextInputForm";
import { TomorrowBox } from "./TomorrowBox";

export function Day4() {
  return (
    <div className="day-wrapper day-4">
      <div className="content-card" style={{ marginTop: "32px" }}>
        <h2>Kapittel 4</h2>
        <p>
          Du trekker sigaretten og lighteren ut av lomma igjen. Ansiktet ditt
          fylles momentant av det gule lyset fra lighter-flammen, men straks
          sitter du igjen med kun den dunkle røde gløden av en nytent suger.
        </p>

        <p>
          &laquo;Kanskje noen av kontaktene mine i Oslos undergrunnsnettverk kan
          gi meg noe å gå på.&raquo;, tenker du, og ser på klokka. Det er midt
          på natta, så du bestemmer deg for å spørre litt rundt i morgen. Du
          røyker opp før du spaserer hjem. Leiligheten din er like ved, tross
          alt.
        </p>

        <p style={{ textAlign: "center", textIndent: 0 }}>* * *</p>

        <p>
          Dagen etter tar du turen til den nærmeste og bruneste puben du kjenner
          til. Det er lyst ute, men ned trappen og inn døren på The Highbury Pub
          er det like mørkt og brunt som alltid. De tapetserte veggene; de runde
          mahogany-bordene; det intrikate gulvteppet; den burgunderrøde
          winchester-sofaen som slanger seg rundt lokalet &mdash; de er alle
          nesten umulige å se bak det enorme fjellet av en dørvakt som stopper
          deg på vei inn.
        </p>

        <p>&laquo;Dette er et lukket selskap. Ingen adgang!&raquo;</p>

        <p>
          Det var en kraftig og buldrete stemme. I et annet liv kunne han
          kanskje ha spilt fortellerstemme i filmtrailere. I et lite sekund
          vurderer du å flekke politiskiltet, men det er kanskje bedre å holde
          seg på den diskré siden. Du prøver deg heller på en kjapp en:
        </p>

        <p>&laquo;Jeg vet det, jeg er leid inn.&raquo;</p>

        <p>Vakten ser skjevt på deg igjennom smale øyne.</p>

        <p>
          &laquo;Det er sant! Jeg skal være underholdning! ... Standup!&raquo;
        </p>

        <p>
          Vakten hever høyre øyenbryn langsomt. Skepsisen henger tjukt som tåke
          i lufta.
        </p>

        <p>&laquo;Si noe morsomt.&raquo;</p>

        <p>Du har blitt satt på prøve. Nå gjelder det å tenke raskt!</p>
      </div>

      <div className="content-card">
        <div className="inventory" style={{ marginTop: "16px" }}>
          <div className="inventory-label">Helsetilstand</div>
          <div style={{ marginTop: "16px", minWidth: "200px" }}>Høy puls.</div>
        </div>
      </div>

      <div className="tagline">Hva svarer du?!</div>
      <TextInputForm day="4" />

      <TomorrowBox
        questions={[
          "Klarer Myrte å snakke seg inn?",
          "Hva slags selskap er det på The Highbury Pub?",
          "Kommer Myrte til å få noe varige mén av sigaretten hun røykte kvelden før?"
        ]}
      />
    </div>
  );
}
