import React, { useContext, useState } from "react";
import { TomorrowBox } from "./TomorrowBox";
import { TrainGame } from "./TrainGame";
import { Store } from "./App";

export function Day15() {
  const store = useContext(Store);
  const [] = useState(false);
  const rawPoints = store.choices && store.choices["15"];
  const points = rawPoints ? +rawPoints : 0;
  return (
    <div className="day-wrapper day-15">
      <div className="content-card" style={{ marginTop: "32px" }}>
        <h2>Kapittel 15</h2>

        <p>
          Du dupper av på flytoget. I drømme har du blitt til et tog selv, og du
          må lete etter spor. Noe forteller deg at om du samler minst 20 spor så
          kan du våkne igjen.
        </p>
      </div>
      <div className="content-card" style={{ padding: 0, overflow: "hidden" }}>
        <TrainGame
          onComplete={points => {
            const previousBest =
              store.choices && store.choices["15"] ? +store.choices["15"] : 0;
            if (points > previousBest) {
              store.select("15", points);
            }
          }}
        />
      </div>

      {points >= 20 && (
        <div className="content-card">
          <p>
            Du klarte det! Du fikk samlet {points} spor. Du våkner og går av på
            perrongen. Flyet ditt går om en liten time, så du rekker det
            sikkert!
          </p>
        </div>
      )}

      {points >= 20 && (
        <>
          <TomorrowBox
            questions={[
              "Vil Myrte rekke flyet?",
              "Hva venter henne i Spania?",
              "Drømmer Myrte ofte at hun er et tog?"
            ]}
          />
        </>
      )}
    </div>
  );
}
