import React from "react";
import { ChoiceForm } from "./ChoiceForm";
import { TomorrowBox } from "./TomorrowBox";

export function Day2() {
  return (
    <div className="day-wrapper day-2">
      <div className="content-card" style={{ marginTop: "32px" }}>
        <h2>Kapittel 2</h2>
        <p>
          Taxien forsvinner nordover langs Ring 2. Glem den nå, du har viktigere
          ting å tenke på! Du fisker telefonen ut av lomma og sveiper inn et
          bestemt mønster på skjermen. Hurtigkoden til nødsentralen sitter i
          muskelminne &mdash; den fikk du lagt inn allerede etter ditt første
          oppdrag for mange år siden.
        </p>

        <p>
          &laquo;Dette er Detektiv Vest. Jeg trenger en ambulanse til
          Majorstukrysset! Politimesteren ser ut til å ha blitt forgiftet.
          Ukjent virkestoff.&raquo;
        </p>

        <p>
          Du setter deg på huk og lener deg over politimesteren. Han griper
          armen din igjennom skjelvingen og treffer øynene dine med et
          penetrerende blikk.
        </p>

        <p>
          &laquo;Du... må... ikke... la... de... slippe... unna... med...
          dette...!&raquo;
        </p>

        <p>Politimesteren er tydelig anstrengt.</p>

        <p>&laquo;Oppsøk... Aksel... Sintra...!&raquo;</p>

        <p>
          Politimesteren slipper taket og senker sammen. I samme stund ruller
          det inn nytt par blålys fra øst. Ambulansen kommer, politimesteren
          løftes inn på båre og kjøres avgårde.
        </p>
        <p>
          &laquo;Hvem kan stå bak dette?&raquo;, tenker du. Taxien har for
          lengst forsvunnet, og du fikk ikke tak i registreringsnummeret heller.
          &laquo;Oppsøk Aksel Sintra!&raquo; Det navnet har du ikke hørt før. Du
          merker at du fortsatt står med en kaffekopp i hånda. Den har blitt
          kald. Du drikker den alikevel med skrumpet ansikt, og kaster den tomme
          koppen i søpla. Der er det noe som fanger oppmerksomheten din. En
          håndskrevet lapp:
        </p>

        <div
          style={{
            position: "relative",
            height: "400px",
            overflow: "hidden"
          }}
        >
          <div
            style={{
              left: "-999px",
              right: "-999px",
              position: "absolute",
              margin: "0 auto",
              background:
                "url(crumpled-sticky-note.jpg) no-repeat center center / contain",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "400px",
              height: "400px"
            }}
          >
            <p
              style={{
                width: "40%",
                fontFamily: '"Patrick Hand SC"',
                transform: "rotate(3deg) translate(0, -10px)"
              }}
            >
              Rendez-vous at 59.9251106,10.7210151. Don't keep Canasta waiting.
            </p>
          </div>
        </div>

        <p>
          Det kan jo være ingenting. Samtidig er det noe ved lappen som gjør at
          du stusser litt. Canasta? Kan det være snakk om Sul Canasta, den
          kjente filantropen? Du bestemmer deg for å ta vare på lappen, bare i
          tilfelle.
        </p>
      </div>

      <div className="tagline">Hva gjør du nå?!</div>

      <ChoiceForm
        day="2"
        choices={[
          "Kjører tilbake til kontoret for å finne ut hva du kan om Aksel Sintra",
          "Drar til rendez-vous-stedet fra lappen for å se om det er noen sammenheng der",
          "Tar deg en røyk og stikker på byen"
        ]}
      />

      <TomorrowBox
        questions={[
          "Hvem er Aksel Sintra?",
          "Er lappen i søpla bare tilfeldig?",
          "Når skal Myrte endelig få tid til å ta seg en røyk?"
        ]}
      />
    </div>
  );
}
