const Matter: any = (window as any).Matter;

export function bootstrapGame(
  canvas: HTMLCanvasElement,
  GameClass: any,
  onComplete: (points: number) => void,
  best: number
) {
  canvas.style.display = "block";
  canvas.style.width = "100%";

  const game = new GameClass(canvas, onComplete, best);

  function resize() {
    const rect = canvas.getBoundingClientRect();
    canvas.width = rect.width * window.devicePixelRatio;
    canvas.height = canvas.width * 1.5;
    game.resize && game.resize();
  }

  window.addEventListener("resize", resize);
  setTimeout(resize);

  let dt = 0;
  let t = +new Date();
  let old_t = t;
  let frame_length_in_ms = 1000 / 60;
  function loop() {
    t = +new Date();
    dt = t - old_t;
    let updates = 0;
    while (dt >= frame_length_in_ms) {
      dt -= frame_length_in_ms;
      game.update();
      updates++;
    }
    if (updates > 1) {
      if (process.env.NODE_ENV === "development") {
        console.log("skipped a frame!", updates);
      }
    }
    old_t = t;

    if (updates > 0) {
      game.render();
    }

    requestAnimationFrame(loop);
  }

  requestAnimationFrame(loop);
}

interface Particle {
  x: number;
  y: number;
  dx: number;
  dy: number;
  life: number;
}

class ParticleSystem {
  particles: Particle[];
  count: number;
  constructor() {
    this.particles = [];
    this.count = 0;

    for (let i = 0; i < 1024; i++) {
      this.particles[i] = {
        x: 0,
        y: 0,
        dx: 0,
        dy: 0,
        life: 0
      };
    }
  }

  fire(x: number, y: number) {
    if (this.count === this.particles.length) {
      return;
    }
    const angle = Math.random() * Math.PI * 2;
    const speed = 2 + 2 * Math.random();
    const dx = speed * Math.cos(angle);
    const dy = speed * Math.sin(angle);
    const p = this.particles[this.count++];
    p.x = x;
    p.y = y;
    p.dx = dx;
    p.dy = dy;
    p.life = 50;
  }

  update() {
    for (let i = 0; i < this.count; i++) {
      const p = this.particles[i];
      p.life--;
      if (p.life === 0) {
        this.particles[i--] = this.particles[--this.count];
        this.particles[this.count] = p;
        continue;
      }
      p.dx *= 0.95;
      p.dy *= 0.95;
      p.x += p.dx;
      p.y += p.dy;
    }
  }

  render(ctx: CanvasRenderingContext2D) {
    ctx.save();
    const size = 2;
    ctx.fillStyle = "#111";
    ctx.globalCompositeOperation = "lighter";
    for (const p of this.particles) {
      ctx.fillRect(p.x - size / 2, p.y - size / 2, size, size);
      ctx.globalAlpha = 0.1 * p.life;
    }
    ctx.restore();
  }
}

export class TowerGameClass {
  canvas: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D | null;
  presentImg: HTMLImageElement;
  isLosing: boolean = false;
  cameraY: number = 0;
  blocks: any[] = [];
  engine: any;
  createNewBlockAtT: number = 0;
  t: number = 0;
  newBlock: { body: any; constraint: any } | null = null;
  fallingBlock: { body: any; constraint: any } | null = null;
  loaded: boolean;
  onComplete: (points: number) => void;
  best: number = 0;
  splashAmount: number;
  splashText: string;

  constructor(
    canvas: HTMLCanvasElement,
    onComplete: (points: number) => void,
    best: number
  ) {
    this.best = best;
    this.onComplete = onComplete;
    this.canvas = canvas;
    this.ctx = this.canvas.getContext("2d");
    this.splashAmount = 0;
    this.splashText = "";
    this.reset();
    this.update();
    this.loaded = false;
    this.presentImg = document.createElement("img");
    this.presentImg.onload = () => {
      this.loaded = true;
    };
    this.presentImg.src = "present.jpg";

    const handlePress = () => {
      if (this.isLosing) {
        this.reset();
      } else {
        this.drop();
      }
    };

    window.addEventListener("keydown", e => {
      if (e.key === " ") {
        e.preventDefault();
        handlePress();
      }
    });

    canvas.addEventListener("touchstart", () => {
      handlePress();
    });
  }

  reset() {
    this.splashText = "";
    this.cameraY = 0;
    this.splashAmount = 0;
    this.blocks = [];
    this.engine = Matter.Engine.create({
      enableSleeping: true
    });
    this.t = 0;
    this.createNewBlockAtT = 0;
    this.isLosing = false;

    Matter.World.add(this.engine.world, [
      Matter.Bodies.rectangle(100, 400, 800, 50, { isStatic: true })
    ]);

    Matter.Events.on(this.engine, "collisionStart", () => {
      navigator.vibrate(16);
    });
  }

  makeNewBlock() {
    const x = 100 + 200 * (Math.random() - 0.5);
    const y = 280 - Math.max(this.blocks.length, 3) * 32;
    const body = Matter.Bodies.rectangle(x, y, 48, 32);
    const block = {
      body,
      constraint: Matter.Constraint.create({
        pointA: {
          x: 100,
          y: y - 150
        },
        bodyB: body
      })
    };
    Matter.World.add(this.engine.world, [block.body]);
    Matter.World.add(this.engine.world, block.constraint);
    this.blocks.push(block);

    if (this.blocks.length > 6) {
      Matter.Body.setStatic(this.blocks[this.blocks.length - 6].body, true);
    }
    return block;
  }

  update() {
    this.splashAmount *= 0.925;
    if (this.splashAmount < 0.001) {
      this.splashAmount = 0;
    }
    if (this.t === this.createNewBlockAtT) {
      this.newBlock = this.makeNewBlock();
      if (this.blocks.length > 4 && (this.blocks.length - 1) % 5 === 0) {
        this.splashText = "" + (this.blocks.length - 1) + "!";
        this.splashAmount = 1;
        navigator.vibrate(200);
      }
      if (this.blocks.length === 41 && this.best < 40) {
        this.onComplete(40);
      }
    }
    this.cameraY =
      this.cameraY * 0.9 + (Math.max(4, this.blocks.length) - 8) * 32 * 0.1;
    Matter.Engine.update(this.engine, 1000 / 60 / 2);
    Matter.Engine.update(this.engine, 1000 / 60 / 2);
    Matter.Engine.update(this.engine, 1000 / 60 / 2);
    Matter.Engine.update(this.engine, 1000 / 60 / 2);
    Matter.Engine.update(this.engine, 1000 / 60 / 2);
    this.t++;

    const originalIsLosing = this.isLosing;
    this.isLosing = this.blocks.length > 1;
    let minY = 99999;
    for (let i = 0; i < this.blocks.length - 1; i++) {
      const block = this.blocks[i];
      minY = Math.min(minY, block.body.bounds.min.y);
      if (block.body.bounds.min.y <= 375 - (this.blocks.length - 1.5) * 32) {
        this.isLosing = false;
      }
    }
    this.isLosing = this.isLosing || originalIsLosing;

    if (!originalIsLosing && this.isLosing) {
      this.best = Math.max(this.blocks.length - 1, this.best);
      this.onComplete(this.blocks.length - 1);
    }
  }

  drop = () => {
    if (!this.newBlock) {
      return;
    }
    Matter.World.remove(this.engine.world, this.newBlock.constraint);
    this.fallingBlock = this.newBlock;
    this.createNewBlockAtT = this.t + 20;
  };

  render() {
    if (!this.ctx) {
      return;
    }
    if (!this.loaded) {
      return;
    }
    this.ctx.save();

    this.ctx.scale(this.canvas.width / 200, this.canvas.height / 1.5 / 200);
    this.ctx.fillStyle = "#ddd";
    this.ctx.fillRect(0, 0, 200, 300);

    this.ctx.save();
    this.ctx.translate(0, this.cameraY);

    this.ctx.fillStyle = "#222";
    this.ctx.fillRect(0, 375, 200, 300);

    this.ctx.fillStyle = "red";

    this.ctx.save();
    this.ctx.save();
    this.ctx.translate(3, 3);
    this.ctx.filter = "blur(4px)";
    this.ctx.fillStyle = "#00000022";
    this.ctx.beginPath();
    for (let i = 0; i < this.blocks.length; i++) {
      const block = this.blocks[i];
      //this.ctx.translate(block.body.position.x, block.body.position.y);
      for (let i = 0; i <= block.body.vertices.length; i++) {
        const vertex = block.body.vertices[i % block.body.vertices.length];
        if (i === 0) {
          this.ctx.moveTo(vertex.x, vertex.y);
        } else {
          this.ctx.lineTo(vertex.x, vertex.y);
        }
      }
      //this.ctx.translate(-block.body.position.x, -block.body.position.y);
    }
    this.ctx.fill();
    this.ctx.restore();

    for (let i = 0; i < this.blocks.length; i++) {
      const block = this.blocks[i];
      this.ctx.save();
      this.ctx.translate(block.body.position.x, block.body.position.y);
      const scaler = 0.76;
      this.ctx.scale((1 / 512) * 64 * scaler, (1 / 512) * 64 * scaler);
      this.ctx.rotate(block.body.angle);
      this.ctx.translate(0, -(345 - 256));
      this.ctx.drawImage(this.presentImg, -256, -256);
      this.ctx.restore();
    }

    if (this.createNewBlockAtT < this.t) {
      this.ctx.beginPath();
      this.ctx.moveTo(100, this.newBlock!.body.position.y - 150);
      this.ctx.lineWidth = 5;
      this.ctx.lineCap = "round";

      this.ctx.strokeStyle = "#222";
      this.ctx.lineTo(
        this.newBlock!.body.position.x,
        this.newBlock!.body.position.y - 2
      );
      this.ctx.stroke();
    }

    this.ctx.restore();
    this.ctx.restore();

    this.ctx.fillStyle = "#222";
    this.ctx.font = '12px "Patrick Hand SC"';
    this.ctx.fillText("Antall: " + (this.blocks.length - 1), 10, 20);
    this.ctx.fillText(
      "Beste: " + Math.max(this.blocks.length - 1, this.best),
      150,
      20
    );
    this.ctx.save();
    this.ctx.textAlign = "center";
    this.ctx.textBaseline = "middle";
    if (this.isLosing || this.blocks.length === 41) {
      this.ctx.fillStyle = "#dddddddd";
      this.ctx.fillRect(0, 0, 200, 300);
      this.ctx.fillStyle = "#222";
      this.ctx.font = '18px "Patrick Hand SC"';
      if (Math.max(this.blocks.length - 1, this.best) < 40) {
        this.ctx.fillText("Game over!", 100, 100);
        this.ctx.fillText("Prøv på nytt", 100, 132);
      } else {
        this.ctx.fillText("Du klarte det!", 100, 100);
      }
    } else {
      if (this.splashAmount > 0) {
        this.ctx.save();
        this.ctx.globalAlpha = 1 - Math.pow(1 - this.splashAmount, 8);
        this.ctx.font = '18px "Patrick Hand SC"';
        this.ctx.translate(100, 50 + 50 * this.splashAmount);
        this.ctx.scale(4 - this.splashAmount * 3, 4 - this.splashAmount * 3);
        this.ctx.fillStyle = "#222";
        this.ctx.beginPath();
        this.ctx.arc(0, 0, 12, 0, 7);
        this.ctx.fill();
        this.ctx.scale(
          0.75 * (1 + 1 * (0.5 - this.splashAmount * 0.5)),
          0.75 * (1 + this.splashAmount)
        );
        this.ctx.save();
        for (let i = 5; i >= 1; i--) {
          this.ctx.fillStyle = `hsl(${(Math.random() * 360) | 0}, 50%, 75%)`;
          this.ctx.fillText(this.splashText, i / 4, i / 4);
        }
        this.ctx.restore();
        this.ctx.fillStyle = "white";
        this.ctx.fillText(this.splashText, 0, 0);
        this.ctx.restore();
      }
    }

    this.ctx.restore();
    this.ctx.restore();
  }
}

function distance(a: { x: number; y: number }, b: { x: number; y: number }) {
  const dx = a.x - b.x;
  const dy = a.y - b.y;
  return (dx ** 2 + dy ** 2) ** 0.5;
}

export class TrainGameClass {
  canvas: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D | null;
  t: number = 0;
  onComplete: (points: number) => void;
  best: number = 0;
  isPressingLeft: boolean = false;
  isPressingRight: boolean = false;
  speed: number = 0;
  angle: number = 0;
  train: { x: number; y: number }[] = [];
  trackCanvas: HTMLCanvasElement;
  trackCanvasCtx: CanvasRenderingContext2D | null;
  apple: { x: number; y: number; icon: string } = { x: 100, y: 150, icon: " " };
  crashed: boolean = false;
  useDeviceMotion: boolean = false;
  gravityX: number = 0;
  gravityY: number = 0;

  constructor(
    canvas: HTMLCanvasElement,
    onComplete: (points: number) => void,
    best: number
  ) {
    this.best = best;
    this.onComplete = onComplete;
    this.canvas = canvas;
    this.ctx = this.canvas.getContext("2d");

    this.trackCanvas = document.createElement("canvas");
    this.trackCanvasCtx = this.trackCanvas.getContext("2d");
    this.reset();
    this.update();

    const handlePress = () => {};

    window.addEventListener("keydown", e => {
      if (e.key === "a") {
        e.preventDefault();
        handlePress();
        this.isPressingLeft = true;
        if (this.crashed) {
          this.reset();
        }
      }
      if (e.key === "d") {
        e.preventDefault();
        handlePress();
        this.isPressingRight = true;
        if (this.crashed) {
          this.reset();
        }
      }
    });

    window.addEventListener("keyup", e => {
      if (e.key === "a") {
        e.preventDefault();
        this.isPressingLeft = false;
      }
      if (e.key === "d") {
        e.preventDefault();
        this.isPressingRight = false;
      }
    });

    window.addEventListener(
      "devicemotion",
      e => {
        if (!e) {
          return;
        }
        if (!e.acceleration) {
          return;
        }
        if (!e.accelerationIncludingGravity) {
          return;
        }
        if (e.accelerationIncludingGravity.x === null) {
          return;
        }
        try {
          this.useDeviceMotion = true;
          this.gravityX =
            (e.accelerationIncludingGravity.x || 0) - (e.acceleration.x || 0);
          this.gravityY =
            (e.accelerationIncludingGravity.y || 0) - (e.acceleration.y || 0);
          let newAngle =
            Math.PI * 2 +
            Math.atan2(this.gravityY, this.gravityX) -
            Math.PI / 2;

          while (this.angle < 0) {
            this.angle += Math.PI * 2;
          }

          while (this.angle > Math.PI * 2) {
            this.angle -= Math.PI * 2;
          }

          newAngle = newAngle % (Math.PI * 2);

          if (newAngle - this.angle > Math.PI) {
            newAngle -= Math.PI * 2;
          }

          if (this.angle - newAngle > Math.PI) {
            newAngle += Math.PI * 2;
          }

          this.angle = this.angle * 0.9 + 0.1 * newAngle;
        } catch (e) {
          alert(e);
        }
      },
      true
    );

    canvas.addEventListener("touchstart", e => {
      handlePress();
      this.isPressingLeft = !!e.touches.length;
      if (this.crashed) {
        this.reset();
      }
    });

    canvas.addEventListener("touchend", e => {
      this.isPressingLeft = !!e.touches.length;
    });
  }

  reset() {
    this.t = 0;
    this.crashed = false;
    this.speed = 0;
    this.angle = Math.PI / 2;
    this.train = [{ x: 100, y: 150 }];
    this.apple = {
      x: 100,
      y: 150,
      icon: " "
    };
  }

  update() {
    this.t++;

    this.speed = 3;
    if (!this.crashed) {
      if (!this.useDeviceMotion) {
        if (this.isPressingLeft) {
          this.angle += 0.1;
        }
        if (this.isPressingRight) {
          this.angle -= 0.1;
        }
      }

      this.train[0].x += this.speed * Math.sin(this.angle);
      this.train[0].y += this.speed * Math.cos(this.angle);

      if (this.train[0].x < 0) {
        this.train[0].x = 0;
      }
      if (this.train[0].x > 200) {
        this.train[0].x = 200;
      }
      if (this.train[0].y > 300) {
        this.train[0].y = 300;
      }
      if (this.train[0].y < 0) {
        this.train[0].y = 0;
      }

      for (let i = 1; i < this.train.length; i++) {
        const previous = this.train[i - 1];
        const current = this.train[i];
        const dx = previous.x - current.x;
        const dy = previous.y - current.y;
        const length = (dx * dx + dy * dy) ** 0.5;
        const amount = 0.5 * Math.max(0, Math.min(1, length / 100));
        current.x = (1 - amount) * current.x + amount * previous.x;
        current.y = (1 - amount) * current.y + amount * previous.y;
      }
    }

    const appleTrainDistance = distance(this.apple, this.train[0]);
    if (appleTrainDistance < 20) {
      const dx =
        this.train.length > 1
          ? this.train[this.train.length - 1].x -
            this.train[this.train.length - 2].x
          : 0;
      const dy =
        this.train.length > 1
          ? this.train[this.train.length - 1].x -
            this.train[this.train.length - 2].y
          : 0;
      this.train.push({
        x: this.train[this.train.length - 1].x + dx * 0.1,
        y: this.train[this.train.length - 1].y + dy * 0.1
      });
      this.apple = {
        x: 10 + Math.random() * 180,
        y: 30 + Math.random() * 260,
        icon: ["📝", "🔍", "🔎", "🧩"][(Math.random() * 4) | 0]
      };
      this.onComplete(this.train.length - 2);
    }

    for (let i = 2; i < this.train.length; i++) {
      const d = distance(this.train[0], this.train[i]);
      if (d < 5) {
        this.crashed = true;
      }
    }
  }

  resize() {
    this.trackCanvas.width = this.canvas.width;
    this.trackCanvas.height = this.canvas.height;
    if (this.trackCanvasCtx) {
      this.trackCanvasCtx.fillStyle = "white";
      this.trackCanvasCtx.fillRect(
        0,
        0,
        this.trackCanvas.width,
        this.trackCanvas.height
      );
    }
  }

  render() {
    if (!this.ctx) {
      return;
    }
    if (!this.trackCanvasCtx) {
      return;
    }

    this.trackCanvasCtx.save();
    this.trackCanvasCtx.fillStyle = "rgba(255, 255, 255, 0.05)";
    this.trackCanvasCtx.fillRect(
      0,
      0,
      this.trackCanvas.width,
      this.trackCanvas.height
    );

    this.trackCanvasCtx.fillStyle = "black";
    this.trackCanvasCtx.scale(
      this.canvas.width / 200,
      this.canvas.height / 1.5 / 200
    );
    this.trackCanvasCtx.translate(
      this.train[this.train.length - 1].x,
      this.train[this.train.length - 1].y
    );
    const lastDx =
      this.train[this.train.length - 1].x -
      this.train[Math.max(this.train.length - 2, 0)].x;
    const lastDy =
      this.train[this.train.length - 1].y -
      this.train[Math.max(this.train.length - 2, 0)].y;
    const lastAngle = Math.atan2(lastDy, lastDx);
    this.trackCanvasCtx.rotate(lastAngle + Math.PI / 2);
    this.trackCanvasCtx.scale(0.8, 0.8);
    this.trackCanvasCtx.translate(0, 8);
    this.trackCanvasCtx.fillRect(-7, -2, 1, 4);
    this.trackCanvasCtx.fillRect(7, -2, 1, 4);
    if (this.t % 2 == 0) {
      this.trackCanvasCtx.fillRect(-9.5, 0, 20, 1);
    }
    this.trackCanvasCtx.restore();

    this.ctx.fillStyle = "#ddd";
    this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);

    this.ctx.save();
    this.ctx.scale(this.canvas.width / 200, this.canvas.height / 1.5 / 200);
    this.ctx.textAlign = "left";
    this.ctx.textBaseline = "alphabetic";
    this.ctx.fillStyle = "#222";
    this.ctx.font = '12px "Patrick Hand SC"';
    this.ctx.fillText("Antall: " + (this.train.length - 2), 10, 20);
    this.ctx.fillText(
      "Beste: " + Math.max(this.train.length - 2, this.best),
      150,
      20
    );
    this.ctx.restore();

    this.ctx.save();
    this.ctx.globalCompositeOperation = "multiply";
    this.ctx.drawImage(this.trackCanvas, 0, 0);
    this.ctx.restore();

    this.ctx.save();

    this.ctx.scale(this.canvas.width / 200, this.canvas.height / 1.5 / 200);

    this.ctx.save();

    this.ctx.textAlign = "center";
    this.ctx.textBaseline = "middle";

    this.ctx.save();
    this.ctx.translate(this.apple.x, this.apple.y);
    this.ctx.scale(2, 2);
    this.ctx.fillText(this.apple.icon, 0, 0);
    this.ctx.restore();

    for (let i = this.train.length; i-- > 0; ) {
      const carriage = this.train[i];
      const next = this.train[Math.max(0, i - 1)];
      this.ctx.save();
      this.ctx.translate(carriage.x, carriage.y);
      this.ctx.scale(-2, 2);
      if (i === 0) {
        this.ctx.rotate(this.angle - Math.PI / 2);
        if (this.crashed) {
          this.ctx.fillText("💥", 0, 0);
        } else {
          this.ctx.fillText("🚅", 0, 0);
        }
      } else {
        const dx = next.x - carriage.x;
        const dy = next.y - carriage.y;

        const angle = Math.atan2(dy, dx);

        this.ctx.rotate(-angle);
        this.ctx.fillText("🚃", 0, 0);
      }
      this.ctx.restore();
    }

    if (this.crashed) {
      this.ctx.fillStyle = "#dddddddd";
      this.ctx.fillRect(0, 0, 200, 300);
      this.ctx.fillStyle = "#222";
      this.ctx.font = '18px "Patrick Hand SC"';
      this.ctx.textAlign = "center";
      this.ctx.textBaseline = "middle";
      this.ctx.fillText("Trykk for å prøve på nytt", 100, 150);
    }

    this.ctx.restore();
    this.ctx.restore();
  }
}
