import React from "react";
import { DotBreak } from "./DotBreak";
import { TextInputForm } from "./TextInputForm";
import { TomorrowBox } from "./TomorrowBox";

export function Day7() {
  return (
    <div className="day-wrapper day-7">
      <div className="content-card" style={{ marginTop: "32px" }}>
        <h2>Kapittel 7</h2>
        <p>
          Du ser deg rundt, og får øye på en tom Cola-flaske som ligger på
          gulvet under ett av bordene.
        </p>

        <p>&laquo;Du tar pant, ikke sant?&raquo;</p>

        <p>
          Hjørdis nikker med en dypsindig mine. Du saumfarer lokalet og klarer å
          skrape sammen 75 kr i pantverdi (!). Det i seg selv er rimelig
          imponerende, men ølen koster 78,-. Hvor skal de siste tre kronene
          komme ifra? Så slår det deg. Ølen du drakk var en svensk øl, så da
          burde jo prisen naturligvis være i svenske kroner. Vekslingsraten
          ligger på rundt 1SEK=0.96NOK. Det går akkurat opp!
        </p>

        <p>&laquo;Can't argue with that!&raquo;</p>

        <p>
          Hjørdis sier seg helt enig i logikken din. Om det var sarkatisk ment
          finner du ikke ut, ettersom du allerede er på vei ut døra fra The
          Highbury Pub.
        </p>

        <DotBreak />

        <p>
          Du bestemmer deg for å dra på et husbesøk til Sul Canasta. Kanskje det
          kan gi frukter? Han bor i et stort herskapshus på Frogner. Det er ikke
          alt for langt unna der du er nå, så du bestemmer deg for å gå til
          fots:
        </p>

        <img
          src="walk-trip.png"
          alt=""
          style={{ width: "100%", borderRadius: "4px", marginTop: "48px" }}
        />

        <p>
          Du er litt anpusten når du kommer fram. (Hei, redusert lungekapasitet
          er en av mange bivirkninger av å røyke. Dessuten har du nettopp
          drukket en øl). Huset er større en du trodde. Du banker på døren.
          Ingen svar. Du prøver en gang til. Fortsatt ingen svar.
        </p>

        <p>
          <em>Bank bank bank.</em>
        </p>

        <div
          style={{
            marginTop: "48px",
            borderRadius: "4px",
            overflow: "hidden",
            background: "#222"
          }}
        >
          <iframe
            src="https://www.google.com/maps/embed?pb=!4v1575685676478!6m8!1m7!1seLOc8DX9C6Ma318418EoUQ!2m2!1d59.92237607767703!2d10.69824060015964!3f242.6626841588926!4f3.1850023961955145!5f0.7820865974627469"
            style={{ border: 0, width: "100%", height: "400px" }}
            allowFullScreen={true}
          ></iframe>
        </div>

        <p style={{ textIndent: 0, textAlign: "center", marginTop: "16px" }}>
          <em>Sul Canastas herskapshus.</em>
        </p>

        <p>
          Fortsatt ingen svar. Du bestemmer deg for å se etter en alternativ
          inngang. Du skal inn, på en eller annen måte! Du saumfarer fasaden
          etter potensielle angrepsvinkler. Et åpent vindu? Skjulte nøkler? En
          hemmelig inngang? En utro tjener? Etter å ha veiet forskjellige
          valgmuligheter, går du for ditt nest beste forslag, på innfall. Senere
          vil det vise seg at det var det riktige valget.
        </p>
      </div>

      <div className="tagline">Hvordan skal du komme deg inn?</div>
      <TextInputForm day="7" />

      <TomorrowBox
        questions={[
          "Vil Myrte klare å finne en inngang?",
          "Betaler Myrte til vanlig med pant på byen?!",
          "Kanskje Myrte burde begynne å snuse i stedet?"
        ]}
      />
    </div>
  );
}
