import React, { useReducer, useEffect, useState } from "react";
import "./App.css";
import { Day0 } from "./Day0";
import { Day1 } from "./Day1";
import { Day2 } from "./Day2";
import { Day3 } from "./Day3";
import { Day4 } from "./Day4";
import { Day5 } from "./Day5";
import { Day6 } from "./Day6";
import { Day7 } from "./Day7";
import { Day8 } from "./Day8";
import { Day9 } from "./Day9";
import { Day10 } from "./Day10";
import { Day11 } from "./Day11";
import { Day12 } from "./Day12";
import { Day13 } from "./Day13";
import { Day14 } from "./Day14";
import { Day15 } from "./Day15";
import { Day16 } from "./Day16";
import { Day17 } from "./Day17";
import { Day18 } from "./Day18";
import { Day19 } from "./Day19";
import { Day20 } from "./Day20";
import { Day21 } from "./Day21";
import { Day22 } from "./Day22";
import { Day23 } from "./Day23";
import { Day24 } from "./Day24";

interface GlobalState {
  refreshData: () => void;
  select: (day: string, choice: any) => void;
  choices?: any;
  day: number;
}

export const Store = React.createContext<GlobalState>({
  refreshData: () => 0,
  select: () => 0,
  day: 0
});

function reducer(state: GlobalState, partial: Partial<GlobalState>) {
  return {
    ...state,
    ...partial
  };
}

function DayWrapper(props: any) {
  const [isOpen, setIsOpen] = useState(false);
  if (isOpen) {
    return props.children;
  }
  return (
    <div className="day-wrapper" style={{ margin: "32px" }}>
      <button
        className="btn btn-primary"
        onClick={e => {
          e.preventDefault();
          setIsOpen(true);
        }}
      >
        Last dag {props.day}
      </button>
    </div>
  );
}

function App() {
  const refreshData = async () => {
    const response = await fetch(process.env.REACT_APP_API_URL + "selections/");
    const data = await response.json();
    dispatch({ choices: data });
  };
  const select = async (day: string, choice: any) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + "select/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          day,
          choice
        })
      });
      if (!response.ok) {
        const text = await response.text();
        alert(text);
      }
    } catch (e) {
      alert(e);
    } finally {
      refreshData();
    }
  };
  const initialState = {
    refreshData,
    select,
    day: window.location.hash
      ? +window.location.hash.slice(1)
      : new Date(+new Date() - 3600 * 5 * 1000).getDate()
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    refreshData();
  }, []);

  return (
    <Store.Provider value={state}>
      {state.day === 0 && <Day0 />}
      <DayWrapper day="1-21">
        <DayWrapper day={1}>{state.day >= 1 && <Day1 />}</DayWrapper>
        <DayWrapper day={2}>{state.day >= 2 && <Day2 />}</DayWrapper>
        <DayWrapper day={3}>{state.day >= 3 && <Day3 />}</DayWrapper>
        <DayWrapper day={4}>{state.day >= 4 && <Day4 />}</DayWrapper>
        <DayWrapper day={5}>{state.day >= 5 && <Day5 />}</DayWrapper>
        <DayWrapper day={6}>{state.day >= 6 && <Day6 />}</DayWrapper>
        <DayWrapper day={7}>{state.day >= 7 && <Day7 />}</DayWrapper>
        <DayWrapper day={8}>{state.day >= 8 && <Day8 />}</DayWrapper>
        <DayWrapper day={9}>{state.day >= 9 && <Day9 />}</DayWrapper>
        <DayWrapper day={10}>{state.day >= 10 && <Day10 />}</DayWrapper>
        <DayWrapper day={11}>{state.day >= 11 && <Day11 />}</DayWrapper>
        <DayWrapper day={12}>{state.day >= 12 && <Day12 />}</DayWrapper>
        <DayWrapper day={13}>{state.day >= 13 && <Day13 />}</DayWrapper>
        <DayWrapper day={14}>{state.day >= 14 && <Day14 />}</DayWrapper>
        <DayWrapper day={15}>{state.day >= 15 && <Day15 />}</DayWrapper>
        <DayWrapper day={16}>{state.day >= 16 && <Day16 />}</DayWrapper>
        <DayWrapper day={17}>{state.day >= 17 && <Day17 />}</DayWrapper>
        <DayWrapper day={18}>{state.day >= 18 && <Day18 />}</DayWrapper>
        <DayWrapper day={19}>{state.day >= 19 && <Day19 />}</DayWrapper>
        <DayWrapper day={20}>{state.day >= 20 && <Day20 />}</DayWrapper>
        <DayWrapper day={21}>{state.day >= 21 && <Day21 />}</DayWrapper>
      </DayWrapper>
      {state.day >= 22 && <Day22 />}
      {state.day >= 23 && <Day23 />}
      {state.day >= 24 && <Day24 />}
    </Store.Provider>
  );
}

export default App;
