import React from "react";
import { TomorrowBox } from "./TomorrowBox";
import { ChoiceForm } from "./ChoiceForm";

export function Day3() {
  return (
    <div className="day-wrapper day-3">
      <div className="content-card" style={{ marginTop: "32px" }}>
        <h2>Kapittel 3</h2>
        <p>
          Snøen daler ned og legger seg som et tungt lokk over lekeplassen. Du
          ligger på lur i klatretårnet og spaner ut mot porten. Fotsporene dine
          har for lengst blitt visket ut, begravd som en hemmelighet snøen ikke
          vil at skal komme ut i offentligheten. Fingrene dine er nesten blå av
          kulde, men du holder ut der du ligger. Kanskje dette var et
          fullstendig blindspor? Kanskje det ikke kommer noen på rendez-vous på
          koordinatene du fant? Eller kanskje de allerede har vært der før du
          kom? Lite trolig &mdash; det begynte ikke å snø før etter du kom. Da
          hadde du vel sett fotspor, tenker du.
        </p>

        <p>
          Om ikke annet, er dette en mulighet for deg å klarne hodet og
          gjennomgå hva det er som har skjedd så langt. Et innbrudd i en
          gullsmed som kanskje er mer enn bare en gullsmed. En politimester som
          blir tatt av dage tilsynelatende for å ha visst for mye. Et ukjent og
          mystisk navn, Aksel Sintra. Og så dette, en lapp i søpla om en
          møteplass som viste seg å være en lekeplass. Hva kan alt dette bety?
        </p>

        <p>
          Du stikker hånda i lomma for å fiske opp en lenge etterlengtet
          sigarett. Om ikke annet vil varmen fra lighteren være kjært for
          fingrene. Akkurat i det du skal til å rive i flinten får du øye på noe
          som beveger seg i mørket. Lighteren går tilbake i lomma. Du kan ikke
          risikere å tenne ild og bli oppdaget nå. En mørk og tykk skikkelse tar
          seg inn igjennom porten og setter seg på husken. Sekunder senere
          kommer det en annen med en koffert.
        </p>
        <p>
          &laquo;Her er utbyttet. Hils Sul og si at alt har gått etter
          planen.&raquo;
        </p>
        <p>
          Kofferten utveksles, og det hele går så fort at man kunne ha gått
          glipp av det helt om man ikke fulgte med. Før den frosne kroppen din
          rekker å reagere, er skikkelsene borte.
        </p>

        <p>
          Du åler deg ut fra gjemmestedet ditt. &laquo;Sul?! Så det <em>er</em>
          han det er snakk om!&raquo; tenker du til deg selv. En arbeidsteori
          begynner å ta form. Sul Canasta, mangemillionær og by-filantrop om
          dagen &mdash; kriminell mesterhjerne på kveldstid. Eller noe sånt. Kan
          det være noe hold i det? Det er ikke mye å gå på, og det høres jo ut
          som noe tatt rett ut av en krimnovelle fra billigkroken på IKEA, men
          det er i det minste en start. Men hva er det som har blitt stjålet,
          egentlig? Og hvorfor ble politimesteren angrepet? Og hva er det han
          prøvde å fortelle deg? Du bestemmer deg for å samle mer informasjon.
        </p>
      </div>

      <div className="content-card">
        <div className="inventory" style={{ marginTop: "16px" }}>
          <div className="inventory-label">Helsetilstand</div>
          <div style={{ marginTop: "16px" }}>
            Frosne fingre, men ellers grei.
          </div>
        </div>

        <div className="inventory">
          <div className="inventory-label">Lommeinnhold</div>
          <div className="inventory-item">
            <div className="inventory-item-image-wrapper">
              <div
                className="inventory-item-image"
                style={{ backgroundImage: "url(lighter.png)" }}
              ></div>
            </div>
            <div className="inventory-item-label">Lighter</div>
          </div>
          <div className="inventory-item">
            <div className="inventory-item-image-wrapper">
              <div
                className="inventory-item-image"
                style={{ backgroundImage: "url(cig.jpg)" }}
              ></div>
            </div>
            <div className="inventory-item-label">Røyk</div>
          </div>
          <div className="inventory-item">
            <div className="inventory-item-image-wrapper">
              <div
                className="inventory-item-image"
                style={{ backgroundImage: "url(crumpled-sticky-note.jpg)" }}
              />
            </div>
            <div className="inventory-item-label">Rendez-vous-lapp</div>
          </div>
          <div className="inventory-item">
            <div className="inventory-item-image-wrapper">
              <div
                className="inventory-item-image"
                style={{ backgroundImage: "url(dust.jpg)" }}
              />
            </div>
            <div className="inventory-item-label">Rusk</div>
          </div>
        </div>
      </div>

      <div className="tagline">Hva gjør du nå?!</div>
      <ChoiceForm
        day="3"
        choices={[
          "Drar tilbake til åsstedet, du har jo ikke utforsket innsiden av gullsmeden enda",
          "Drar på et opportunistisk besøk til Sul Canastas herskapshus på Frogner, kanskje du kan finne noe der?",
          "Får i deg litt varme i kroppen ved å fyre opp siggen før du stikker på puben for å spørre litt rundt"
        ]}
      />

      <TomorrowBox
        questions={[
          "Hva var i kofferten?",
          "Går det bra med de igjennomfrosne fingrene til Myrte?",
          "Kanskje sigaretten er en metafor, og det er derfor den aldri rekker å blåses?"
        ]}
      />
    </div>
  );
}
