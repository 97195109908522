import React from "react";
import { TomorrowBox } from "./TomorrowBox";

export function Day22() {
  return (
    <div className="day-wrapper day-22">
      <div className="content-card" style={{ marginTop: "32px" }}>
        <h2>Kapittel 22</h2>
        <p>&laquo;Vi må komme oss opp til ham!&raquo;</p>

        <p>Sinterklaas henter et uteserveringsbord og demonterer toppen.</p>
        <p>&laquo;Her, sett deg på denne!&raquo;</p>
        <p>
          Du setter deg på bordet med et spørrende ansiktsuttrykk. Hva skjer nå?
        </p>

        <p>
          Sinterklaas henter ut sin magiske stav fra inni skjegget og begynner å
          synge en magisk julesang.
        </p>

        <p>
          <em>
            &laquo;Sinterklaas kapoentje
            <br />
            Gooi wat in mijn schoentje
            <br />
            Gooi wat in mijn laarsje
            <br />
            Dank u, Sinterklaasje.
          </em>
        </p>

        <p>
          Bordet begynner å lette opp fra bakken. Du flyr! Sinterklaas styrer
          deg opp mot sleden til Canasta.
        </p>

        <p>Samtidig løper Julenissen bort til de skadde.</p>
        <p>&laquo;Jeg prøver å få folk i sikkerhet!&raquo;</p>

        <p>
          Julenissen trekker ut magisk, skitten, helbredende mose fra skjegget
          sitt og trykker det inn i de store åpne kjøttsårene til de skadde
          forbipassende juleshopperne som ligger strødd i gatene.
        </p>

        <p>
          Du holder deg fast for harde livet i det bordet du sitter på nærmer
          seg sleden til Sul Canasta. Det føles ut som at det er lenge siden
          Sinterklaas har flydd et bord &mdash; alt vakler og du faller nesten
          av.
        </p>

        <p>
          &laquo;Stopp med en gang, i lovens navn!&raquo;, roper du. Kanskje
          Canasta hører etter?
        </p>
        <p>
          Han hører ikke etter. Han slipper noen julegavebomber til før han
          retter fokuset sitt mot deg. Du er nå så nære at du kanskje kan klare
          å hoppe over i hans metallslede. Du gjør et forsøk på et sprang, men
          sklir på bordet i det du tar sats. Du når fram til sleden, men bare så
          vidt. Fingrene dine får klamret seg fast til kanten, men resten av
          kroppen henger og dingler ut i det fri. det må være 20-30 meter ned
          til Valkyrien under deg. Nå er gode råd dyre!
        </p>

        <p>Canasta begynner å le. Han plukker opp en julegavebombe til.</p>

        <p>&laquo;Denne er til deg. Du har vært en slem detektiv!&raquo;</p>

        <p>
          Han tar tak i håndleddene dine og løfter de fra sledekanten og over
          til julegaven. Så slipper han tak. Du faller ned mot bakken med gaven
          i hendene.
        </p>
      </div>

      <TomorrowBox
        questions={[
          "Hvordan vil Myrte overleve dette?!",
          "Vil heltene våre klare å stoppe Santa Claus?",
          "Hva skjer med alle de skadde?"
        ]}
      />
    </div>
  );
}
