import React, { useContext, useState, useEffect } from "react";
import { Store } from "./App";

interface Props {
  day: string;
}

export function TextInputForm(props: Props) {
  const inputId = `text-input-${props.day}`;

  const store = useContext(Store);

  const [value, setValue] = useState(
    (store.choices && store.choices[props.day]) || ""
  );

  useEffect(() => {
    setValue(store.choices && store.choices[props.day]);
  }, [store.choices && store.choices[props.day], props.day]);

  const isLocked = !!(store.choices && store.choices[props.day]);

  return (
    <div className="choose-selector">
      <form
        id={`text-input-form-${props.day}`}
        style={{ fontFamily: "Patrick Hand SC !important" }}
        onSubmit={e => {
          e.preventDefault();
          store.select(props.day, value);
        }}
      >
        <div className={"choice" + (isLocked ? " active" : "")}>
          <div className="form-group">
            <label htmlFor={inputId}>Ditt svar</label>
            <div style={{ display: "flex" }}>
              <input
                type="text"
                readOnly={isLocked}
                id={inputId}
                name={`text-input-form-input-${props.day}`}
                value={value}
                onChange={e => setValue(e.target.value)}
                className="form-control"
                placeholder="..."
                style={{
                  flex: 1,
                  fontFamily: '"Patrick Hand SC"',
                  fontSize: "24px"
                }}
              />
              <button
                className="btn btn-primary"
                disabled={isLocked}
                style={{
                  marginLeft: "8px",
                  fontFamily: '"Patrick Hand SC"',
                  fontSize: "24px"
                }}
              >
                Send inn
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
