import React from "react";
import { DotBreak } from "./DotBreak";
import { TomorrowBox } from "./TomorrowBox";

export function Day8() {
  return (
    <div className="day-wrapper day-8">
      <div className="content-card" style={{ marginTop: "32px" }}>
        <h2>Kapittel 8</h2>
        <p>
          Det har allerede begynt å bli mørkt. Ikledd det samme som du har hatt
          på deg siden begynnelsen av denne historien, smyger du deg bort til
          der Sul Canastas tomt slutter og naboens starter. Du har fått øye på
          et steingjerde der du kan bruke for å klatre opp til den steinbelagte
          terrassen til Canasta. Nå er det viktig at ingen ser deg, tenker du.
          Du vrenger ytterjakka slik at refleksene peker innover mot kroppen.
          Noen ganger imponerer du deg selv, og dette er en av de gangene. Om
          ikke annet er det grunn for å være imponert over jakken selv &mdash;
          glidelåsen har blitt så dårlig i det siste at den ofte slår seg helt
          vrang og blir umulig å rokke på. Du blir stående og fundere på hvordan
          du skal komme deg videre fra terrassen og inn i selve bygget. Du lener
          deg mot en av løvestatuene som pryder port-inngangen, men den gir
          etter! Etter nærmere undersøkelser finner du at løven skjuler en
          bekmørk tunnellinngang som går ned under bakken. Hendene dine finner
          en stige og du bestemmer deg for at det er nå eller aldri. Du visker
          til deg selv:
        </p>

        <p>
          &laquo;Jeg håper ikke dette er en trap. Men samtidig må jeg bare ta
          sjanser nå og komme meg inn.&raquo;
        </p>

        <p>
          Forsiktig setter du føttene dine på det øverste trinnet og går inn. I
          mørket. Inn i det som forhåpentligvis leder til Sul Canastas hus.
        </p>

        <DotBreak />

        <p>
          Stigen er lengere enn det du hadde sett for deg. Trinn etter trinn tar
          den deg dypere ned i mørket. Etter det som føles ut som alt for lenge
          når du brått bunnen. Det er helt svart. Du griper etter mobilen i
          jakkelomma, men kommer på at den er nå på innsiden av jakka som er på
          vranga. I mørket fomler du og får ikke åpnet glidelåsen, som har satt
          seg fast igjen. Det ser ut til at det får bli uten lys enn så lenge.
        </p>

        <p>
          Hendene dine finner en vegg. Den føles fuktig ut, men ikke våt. Kald,
          men ikke frossen. Den kunne ha vært av stein eller hard jord. Eller
          kanskje grov sement. Med veggen på venstre side begynner du å sakte
          bevege deg igjennom mørket. Det går langsomt, men forover. Du tar ett
          skritt av gangen. En hånd på veggen, den andre foran deg. 10 meter. 50
          meter. 200 meter. Det fortsetter og fortsetter. På dette punktet er du
          ikke en gang sikker på om du ville ha klart å finne veien tilbake.
          Eneste mulighet er å fortsette. Omsider skimter du et svakt lys i det
          fjerne. Lyset blir gradvis sterkere og større i det du nærmer deg. Så
          får du øye på lyskilden.
        </p>
        <p>Det er en dør.</p>
        <p>Den er låst.</p>
        <p>Du er lost.</p>
        <p>Du dør.</p>

        <p>
          Inni deg. Altså i overført betydning. Det vil si, døden er det. Døra
          er ekte. Du tar deg sammen og prøver å huske tilbake til
          detektivskolen. Hva var det dørfagmester Porten Dirkeland alltid sa?
          &laquo;Der det finnes en låst dør finnes det alltid en nøkkel, for
          noen.&raquo; Du leter rundt og finner en nøkkel. Det var jo nesten for
          enkelt. Du tenker ikke over det. Nøkkelen passer i låsen. Du vrir om
          og åpner døra. Bak den er
          <span className="censored">XXXXXX XX XXXXX XX XXXX XXX</span>. Du blir
          sjokkert.
          <span className="censored">XXXXXXX XXXXXXXX</span> er den siste
          personen du ville ha forventet å se der.
          <span className="censored">XXXXXXX XXXXXXXX</span> smiler og vinker
          med et <span className="censored">XXXX</span> rundt{" "}
          <span className="censored">XXXXXXX</span>.
          <span className="censored">
            XXXXXX XX XXXXX XX XXXX XXX XXXXXX XXX XXX
          </span>
          , og deretter{" "}
          <span className="censored">XXXXXX XX XXXX XXX XXXXXX XXX XXX</span>.
          <span className="censored">XX XXXX XXX XXXXXX XXX XXX</span> og{" "}
          <span className="censored">XXXXXX XXX XXX</span> vaskemaskin{" "}
          <span className="censored">XX XXXX XXXXX XXX</span> fallskjermutløser
          <span className="censored">
            XX XXXX XXX XXXXXX XXX XXX XXXXX XXXXXXXX
          </span>{" "}
          en stor fisk
          <span className="censored">XXXX XXX</span> Dalai Lama.
        </p>
      </div>

      <TomorrowBox
        questions={[
          "Hva skjuler det seg bak døra?",
          "Hvor langt har du beveget deg under bakken, hvor er du nå?!",
          "Vaskemaskin fallskjermutløser en stor fisk Dalai Lama?"
        ]}
      />
    </div>
  );
}
