import React from "react";
import { TomorrowBox } from "./TomorrowBox";
import { DotBreak } from "./DotBreak";

export function Day13() {
  return (
    <div className="day-wrapper day-13">
      <div className="content-card">
        <h2>Kapittel 13</h2>
        <p>
          Sul Canasta. Mannen du har jaktet på. Så hemmelighetsfull. Så
          vanskelig å få tak i. Endelig har du ham. Eller er det han som har
          deg?
        </p>
        <p>
          &laquo;Politimester Jeneus. Du kom deg løs. Jeg skal ikke late som at
          jeg ikke er imponert. Og du er?&raquo;
        </p>
        <p>Sul ser på deg i det han spør.</p>
        <p>
          &laquo;Detektiv Vest&raquo;, svarer du, og retter et tvilende blikk
          mot politimesteren. Sa han at han kjente Sul Canasta fra før? Hva er
          det som foregår her egentlig?
        </p>
        <p>
          Alle blir stående og se på hverandre. En slags standoff. Hvem tar det
          neste trekket? Hva er spillet? Hva er det dere spiller om?
        </p>
        <p>&laquo;Detektiv Vest? Aldri hørt om.&raquo;</p>
        <p>Han snur seg mot politimesteren.</p>
        <p>
          &laquo;Så du har fått rekruttert deg en assistent. Det betyr
          ingenting. Du vil ikke kunne stå i mellom meg og mine planer. Endelig
          skal jeg få tatt tilbake det som rettmessig tilhører meg!&raquo;
        </p>
        <p>
          Politimesteren virker ikke imponert. Han knurrer: &laquo;Hvor er
          lua?&raquo;
        </p>
        <p>
          <em>Lua?</em> 🤔
        </p>
        <p>
          &laquo;Den får du aldri se igjen! Nå er det endelig jeg får kontrollen
          tilbake. Jeg som kan bestemme. Jeg som kan dømme. Jeg som kan bestemme
          hvem som skal belønnes. Hvem som skal straffes! Det er ingen som kan
          stanse meg nå som jeg har fått tak i lua. Mange år med planlegging har
          endelig sprunget til live! Hevnen skal endelig bli min. Du er
          maktesløs. Aksel er maktesløs. Nå er det min tid! &raquo;
        </p>
        <p>
          <em>Aksel? Sintra?</em> 🤔
        </p>
        <p>
          &laquo;Aksel Sintra?&raquo;, spør du, før du rekker å stanse deg selv.
        </p>
        <p>
          &laquo;Å, så du vet om Aksel? Du er smartere enn du ser ut. Han sitter
          vel fast i Spania tenker jeg. Uansett spiller det ingen rolle. Jeg har
          ikke tenkt å stå her og forklare min hemmelige plan. Hva skal dette
          liksom være, en James Bond-film? Nei, jeg alt for mye å gjøre nå som
          jeg har fått tilbake makten!&raquo;
        </p>
        <p>
          Med det trekker han fram en stor og rød nisselue fra baklomma. Den er
          ordentlig fin, rekker du så vidt å tenke. Tykk og perlete hvit pels
          som glanser i lyset. Dyp og rik rødfarge. Den ser myk ut. Han tar på
          seg nisselua og så eksploderer alt i en sky av sort glitter. Du mister
          retningssansen. Står du eller ligger du? Sekunder senere er alt over.
          Sul er borte. Politimesteren er borte. Rommet du stod i er borte. Du
          befinner deg hjemme, av alle steder.
        </p>

        <DotBreak />

        <p>
          Hva var alt det der egentlig? Sul Canasta er en ond trollmann liksom?
          Med en magisk nisselue? Er det den han har stjålet? Hva var det han
          snakket om, hvem skal dømmes? Straffes? Og hvorfor hadde han egentlig
          et kjellernettverk med tusenvis av julegaver?
        </p>

        <p>
          Hvem <em>er</em> Sul Canasta?
        </p>

        <p>
          <em>Sul Canasta.</em>
        </p>

        <p>
          <em>Sul Canasta...</em> 🤔
        </p>

        <p>Plutselig går det opp for deg.</p>
      </div>

      <TomorrowBox
        questions={[
          "Hva er det du plutselig forstår?",
          "Hvor ble det av Canasta og politimesteren?",
          "Så politimesteren heter Jeneus? Kjekt å vite!"
        ]}
      />
    </div>
  );
}
