import React, { useContext, useState } from "react";
import { TomorrowBox } from "./TomorrowBox";
import { DotBreak } from "./DotBreak";
import { TowerGame } from "./TowerGame";
import { Store } from "./App";

export function Day10() {
  const store = useContext(Store);
  const [showGame, setShowGame] = useState(false);
  const rawPoints = store.choices && store.choices["10"];
  //const points = rawPoints ? +rawPoints : 0;
  const points = 100;
  return (
    <div className="day-wrapper day-10">
      <div className="content-card" style={{ marginTop: "32px" }}>
        <h2>Kapittel 10</h2>

        <p>
          Du plukker opp en pakke fra haugen. Hardpakke. Grei størrelse. Virker
          stabil. Dette kan faktisk funke, tenker du. Du begynner å samle sammen
          et par pakker og setter en oppå den andre. Tårnet blir ikke høyt før
          det raser sammen.
        </p>

        <p>&laquo;Again!&raquo;</p>

        <p>
          Tårnet blir høyere denne gangen, men det begynner å bli vanskelig å nå
          opp til toppen for å legge på neste pakke. Du prøver å kaste neste
          pakke opp på toppen, men når akkurat ikke opp. Alt raser sammen igjen.
        </p>

        <p>&laquo;Again!&raquo;</p>

        <p>
          Du gir deg ikke enda. Det må finnes en smartere metode! Du ser
          oppover. Minst 40 pakker må til for å komme seg ut, ser det ut som. Da
          må man gå smartere til verks. Du ser rundt deg etter noe som kan
          hjelpe. Alt du finner er stolen som politimesteren satt på, og det du
          har i lomma. Du trekker fram fallskjermutløseren som politimesteren
          hadde i hånda. Kanskje det går an å bygge en slags heisekran-anordning
          med det som er tilgengelig?
        </p>

        <DotBreak />

        <p>
          Etter mye prøving og feiling har du noe som ser ut til at det kanskje
          kan funke. En slags hjemmelaget løfte-mekanikk som klarer å slippe
          pakkene i en stabel. Blir stablen høy nok får dere til å klatre ut. Du
          begynner å stable:
        </p>
      </div>
      <div className="content-card" style={{ padding: 0, overflow: "hidden" }}>
        {!showGame && (
          <button className="btn btn-primary" onClick={() => setShowGame(true)}>
            Start spill
          </button>
        )}
        {showGame && (
          <TowerGame
            onComplete={points => {
              const previousBest = store.choices ? +store.choices["10"] : 0;
              if (points > previousBest) {
                store.select("10", points);
              }
            }}
          />
        )}
      </div>

      {points >= 40 && (
        <div className="content-card">
          <div>
            <p>
              Det gikk! Etter mye om og men er tårnet klart. Du legger an først.
              Sakte og forsiktig åler du deg opp, pakke etter pakke. Omsider når
              du en platform som går inn i veggen. Du kaster deg mot platformen
              med et elegant byks. Så er det politimesteren sin tur. Av en eller
              annen grunn er politimesteren oppe på null komma niks. Vel oppe
              ser dere at platformen ender i en dør. Denne gangen er den ulåst.
              Med et kjapt blikk rundt karmen for å se etter flere feller griper
              du tak i dørhåndtaket og vrir om.
            </p>
          </div>
        </div>
      )}

      {points >= 40 && (
        <>
          <TomorrowBox
            questions={[
              "Hvor er heltene våre nå?",
              "Hvorfor er politimesteren så flink til å klatre oppover?",
              "Hva skjuler seg bak neste dør?"
            ]}
          />
        </>
      )}
    </div>
  );
}
