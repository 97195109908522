import React, { useState, useEffect, useContext } from "react";
import { TomorrowBox } from "./TomorrowBox";
import { DotBreak } from "./DotBreak";
import { Store } from "./App";

function AnagramSolver(props: {
  start: string;
  end: string;
  onChange: (guess: string, taken: string, isSolved: boolean) => void;
  initialGuess: string;
  initialTaken: string;
}) {
  const [guess, setGuess] = useState(props.initialGuess || "");
  const [taken, setTaken] = useState(props.initialTaken || props.start);
  useEffect(() => {
    props.onChange(guess, taken, guess === props.end);
  }, [guess, props, taken]);
  return (
    <div>
      <p>Detektiv Myrte Vest er:</p>
      <div
        style={{
          display: "flex",
          marginTop: "32px",
          marginBottom: "16px"
        }}
      >
        {props.start.split("").map((letter, i) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "64px",
              height: "64px",
              fontSize: "24px",
              borderRadius: "4px",
              fontWeight: "bold",
              fontFamily: "Patrick Hand SC",
              marginRight: "8px",
              background: taken[i] === "_" ? "#eee" : "#aee371",
              color: taken[i] === "_" ? "#ccc" : "white",
              textShadow:
                taken[i] === "_" ? "2px 2px 0 #aaa" : "2px 2px 0 black",
              boxShadow:
                taken[i] === "_" ? "4px 4px 0 #ddd" : "4px 4px 0px #75a142",
              cursor: "pointer"
            }}
            onClick={e => {
              e.preventDefault();
              if (taken[i] === "_") {
                return;
              }
              setTaken(taken.slice(0, i) + "_" + taken.slice(i + 1));
              let guessIndex = guess
                .split("")
                .findIndex(letter => letter === " ");
              if (guessIndex === -1) {
                guessIndex = guess.length;
              }
              setGuess(
                guess.slice(0, guessIndex) +
                  props.start[i] +
                  guess.slice(guessIndex + 1)
              );
            }}
          >
            {letter}
          </div>
        ))}
      </div>

      <div
        style={{
          display: "flex"
        }}
      >
        {props.start.split("").map((_, i) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "64px",
              height: "64px",
              fontSize: "24px",
              borderRadius: "4px",
              fontWeight: "bold",
              fontFamily: "Patrick Hand SC",
              marginRight: "8px",
              background:
                guess[i] === props.end[i]
                  ? "#ffc800"
                  : (guess[i] || "").trim()
                  ? "#888"
                  : "#eee",
              color: guess[i] === props.end[i] ? "black" : "white",
              textShadow:
                guess[i] === props.end[i]
                  ? "2px 2px 0 #ffeeb3"
                  : "2px 2px black",
              boxShadow:
                "4px 4px 0px " +
                (guess[i] === props.end[i]
                  ? "#b59319"
                  : (guess[i] || "").trim()
                  ? "#666"
                  : "#ddd"),
              cursor: "pointer"
            }}
            onClick={e => {
              e.preventDefault();
              if (props.end === guess) {
                return;
              }
              const originalIndex = props.start
                .split("")
                .findIndex(
                  (letter, j) => letter === guess[i] && taken[j] === "_"
                );
              setTaken(
                taken.slice(0, originalIndex) +
                  guess[i] +
                  taken.slice(originalIndex + 1)
              );
              setGuess(guess.slice(0, i) + " " + guess.slice(i + 1));
            }}
          >
            {guess[i]}
          </div>
        ))}
      </div>
    </div>
  );
}

export function Day24() {
  const store = useContext(Store);
  const [isSolved, setIsSolved] = useState(false);
  return (
    <div className="day-wrapper day-24">
      <div className="content-card" style={{ marginTop: "32px" }}>
        <h2>Kapittel 24</h2>

        <p>Hvordan stjele julen i 5 enkle steg &mdash; en mestertyvs plan.</p>

        <h3 style={{ marginTop: 32 }}>Steg 1</h3>
        <p>
          Oppdag at det finnes 3 nisser som deler på julen, men at 1 av de er
          antagonisert og strippet for sine nissekrefter. Dette kan for eksempel
          gjøres ved å henge på internett og lese alt for mange forum-tråder på
          sketsjie nettsider.
        </p>

        <h3 style={{ marginTop: 32 }}>Steg 2</h3>

        <p>
          Finn gullsmeden som vokter den magiske nisseluen til nissen som lever
          i eksil. Betal noen amatørmessige kjeltringer til å bryte seg inn og
          overlevere luen til nisseluens rettmessige eier, enn så lenge. (Det
          hadde ikke gått å bare ta vare på luen selv på dette tidspunktet
          &mdash; da hadde man bare mistet den i kamp til de to andre nissene).
        </p>

        <h3 style={{ marginTop: 32 }}>Steg 3</h3>

        <p>
          Allier deg med de andre to nissene i rollen din som detektiv som
          etterforsker innbruddet. Her må du late som at du ikke vet hva det er
          som holder på å skje, slik at du får etablert troverdighet.
        </p>

        <h3 style={{ marginTop: 32 }}>Steg 4</h3>
        <p>
          Led nissene inn i slosskamp mot hverandre. Sett deg selv i fare for å
          sette opp et ultimatum. Pass på at nissene på ditt lag må ofre seg
          selv for å ta ut den andre.
        </p>

        <h3 style={{ marginTop: 32 }}>Steg 5</h3>
        <p>
          Sørg for at nisseluen overlever, og at den ender opp i dine hender.
          Gratulerer, du har nå stjålet julen. De gamle nissene er døde.
          Nisseluen og dens magiske krefter tilhører deg.
        </p>

        <DotBreak />

        <p>
          Er det sant? Er det virkelig Detektiv Myrte Vest som står bak? Går det
          an? Er det hele stelt i stand av en kalkulerende mestertyv som har
          lurt alle de involverte trill rundt?
        </p>

        {store.choices && (
          <AnagramSolver
            start="MYRTEVEST"
            end="MESTERTYV"
            initialGuess={(store.choices["24"] || "|").split("|")[0]}
            initialTaken={(store.choices["24"] || "|").split("|")[1]}
            onChange={(guess, initialTaken, newIsSolved) => {
              setIsSolved(newIsSolved);
              store.select("24", guess + "|" + initialTaken);
            }}
          />
        )}

        {isSolved && (
          <>
            <p>
              Gratuluerer! Du har nå i praksis blitt en gud. Julens magiske
              krefter er dine. Hva vil du gjøre med denne makten? Du kan nå selv
              forme julen rundt deg. Hvordan du bruker disse nye kreftene dine
              er opp til deg.
            </p>

            <div
              className="tagline"
              style={{ marginTop: 64, marginBottom: 32 }}
            >
              Slutt.
            </div>
          </>
        )}
      </div>
    </div>
  );
}
