import React from "react";
import { TomorrowBox } from "./TomorrowBox";

export function Day16() {
  return (
    <div className="day-wrapper day-16">
      <div className="content-card" style={{ marginTop: "32px" }}>
        <h2>Kapittel 16</h2>

        <p>
          Du rekker flyet så vidt. Flyturen i seg selv er ikke så innholdsrik.
          Det mest interessante er kanskje at du har fått 2 spyposer i
          sete-lomma. En til, så kunne du ha sjonglert med de. Du får tiden til
          å <em>fly</em> (heh) ved å benytte deg av in-flight entertainment. De
          viser gamle episoder av Friends. Det er OK.
        </p>

        <p>
          Flyplassen i Madrid er ikke så stor som man kanskje skulle ha trodd.
          Eller, kanskje den er større enn det man skulle ha trodd. Det kommer
          an på hva man trodde på forhånd. Hva slags forutantagelser man hadde
          gjort seg opp. Dette er det du tenker på i det du går igjennom "EU /
          ingenting å fortolle" (selv om du egentlig lurer på om det burde ha
          vært en egen sluse for EØS). Ut på den andre siden er det er kokende
          folkehav av privatsjåfører som holder opp plakater med navn på.
          &laquo;Her er det alltid morsomt å lese. Hvem er det som blir hentet
          sånn egentlig, det er litt tacky, er det ikke?&raquo; tenker du. Du
          skummer over skiltene. Robiera Garcia. Amblo Pabeltero. Guilliermo
          Baltarga. Detektiv Myrte Vest.
        </p>

        <p>
          Du setter nesten tunga i halsen. <em>Detektiv Myrte Vest?</em> Hvem
          kan ha visst at jeg kom? Hvem kan være interessert i å hente meg?
        </p>

        <p>
          Sjåføren som holder skiltet har på seg et nesten parodisk fint
          antrekk. Det ser ut som et butlerantrekk tatt rett fra Downton Abbey.{" "}
          <em>Detektiv Myrte Vest</em>. Du bestemmer deg for å utforske, og går
          opp og introduserer deg selv. Sjåføren ser på deg:
        </p>

        <p style={{ color: "gold", textShadow: "1px 1px 0 black" }}>
          &laquo;Listo. Te hemos estado esperando.&raquo;
        </p>

        <p>
          Han sier ikke mer, men gestikulerer og går ut. Du følger etter. Han
          gir deg en hjelm og setter seg på en stor rød motorsykkel. Den raske
          japanske varianten, ikke den kriminelle amerikanske. Du hopper på og
          dere suser avgåde inn i natten.
        </p>
      </div>

      <TomorrowBox
        questions={[
          "Hvem er det som venter Myrte?",
          "Hvor er de på vei?",
          "Kanskje den ekstra spyposen bare tilhørte naboen?"
        ]}
      />
    </div>
  );
}
