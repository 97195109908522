import React from "react";
import { ChoiceForm } from "./ChoiceForm";
import { TomorrowBox } from "./TomorrowBox";

export function Day6() {
  return (
    <div className="day-wrapper day-6">
      <div className="content-card" style={{ marginTop: "32px" }}>
        <h2>Kapittel 6</h2>
        <p>
          Hjørdis Grythe er en person med ører. Med det mener jeg at hun hører
          ting. Ting som andre sier. Ting som det ikke er meningen at hun skal
          vite om. Fra bak bardisken sjenker hun klientelet, og hemmelighetene
          flyter i retur.
        </p>

        <p>
          &laquo;En øl&raquo;, bestiller du. &laquo;Og hva er greia med
          dørvakta?&raquo;
        </p>

        <p>
          &laquo;Detektiv Vest! Et sjeldent syn i disse dager. Hva bringer deg
          hit?&raquo;
        </p>

        <p>
          Hjørdis begynner å helle opp en halvliter, og fortsetter uten å vente
          på svar:
        </p>

        <p>
          &laquo;Man kan aldri være for sikker i disse dager. Da jeg hørte om
          innbruddet i går natt stengte jeg for allmenheten for ubestemt tid. Nå
          er det bare de jeg kjenner og kan stole på som får slippe inn.&raquo;
        </p>

        <p>Du får ølen i hånda mens hun greier ut.</p>

        <p>
          &laquo;Ryktene sier at det ikke var noe vanlig innbrudd. Jeg har hørt
          at det skal ha vært et bestillingsverk på oppdrag fra Sul Canasta. Men
          hva skal han med gull og diamanter? Han vasser jo allerede i penger.
          Nei, praten går i at det var noe annet som var målet. Og at noe gikk
          galt.&raquo;
        </p>

        <p>&laquo;Så derfor holder du stengt?&raquo;</p>

        <p>
          &laquo;Jeg har en anelse om hvem det var som ble arrestert, og de
          pleide å komme innom her av og til. Jeg trenger ikke at noen skal
          grave etter hevn her inne, for å si det sånn.&raquo;
        </p>

        <p>
          Så ryktene sprer seg raskt i miljøet. Hvordan har Hjørdis hørt om den
          mulige koblingen med Canasta? Du vet at det nytter ikke å spørre hvem
          hun har hørt det fra &mdash; som detektiv i en undergrunnsbule vet du
          at det er visse regler som må følges. Hjørdis forteller gjerne hva
          ordet går i om dagen, men aldri hvem som har sagt det.
        </p>

        <p>
          Det har blitt klart at Canasta definitivt har en finger med i spillet.
          Rendez-vous-lappen, møtet i lekeplassen, og nå informasjonen fra
          Hjørdis. Du prater litt og smått og stort mens du jobber deg igjennom
          ølen, og til slutt reiser du deg for å gå.
        </p>

        <p>&laquo;Hei, du må betale før du går!&raquo;</p>

        <p>
          Heh, det pleide ikke alltid å være sånn, Hjørdis, men ok, tenker du.
          Du flekker fram betalingsmetoden du alltid pleier å bruke når du er på
          byen.
        </p>
      </div>

      <div className="tagline">Hvilken betalingsmetode velger du?!</div>
      <ChoiceForm
        day="6"
        choices={["Bankkort", "Vipps", "Betale med flaskepant"]}
      />

      <TomorrowBox
        questions={[
          "Snakker Hjørdis sant?",
          "Har Myrte dekning på kortet/vipps/nok flasker med seg til å betale med?",
          "Når skal Myrte finne ut mer om navnet Aksel Sintra?"
        ]}
      />
    </div>
  );
}
