import React from "react";
import { ChoiceForm } from "./ChoiceForm";
import { TomorrowBox } from "./TomorrowBox";

export function Day9() {
  return (
    <div className="day-wrapper day-9">
      <div className="content-card" style={{ marginTop: "32px" }}>
        <h2>Kapittel 9</h2>

        <p>
          Plutselig innser du at du har blitt sensurert. Noen sitter med
          CIA-tusj og skraverer bort store deler av hva du tenker. For noen
          motherf<span className="censored">riend</span>s. Det er ikke lett å
          håndtere en situasjon som dette. Hvis man tenker for hardt på å finne
          ut hvem som står bak, så oppdager de bare hva du holder på med, og
          sensurerer det bort. Du bestemmer deg for å blanke ut hjernebølgene
          dine ved å gjenta og gjenta Porten Dirkelands 3 lover om dørfag inni
          deg, slik du pleide å gjøre på detektivskolen.
        </p>

        <p>
          Etter en stund kommer du inn i en slags mantra-transe, og oppdager at
          du bare blir sensurert når du går igjennom døråpningen. Før du går
          igjennom døra er det ikke noe problem. Du begynner å granske døra i
          detalj. Hver centimeter undersøkes. Gjemt i listverket finner du en
          Sensor-o-matic 3000™ Reality Distortion and Redaction unit. En maskin
          som kan sensurere tanker. Den er rigget opp til å sensurere tankene
          til alle som går igjennom døra. Du skrur den av, og åpner døra igjen.
        </p>

        <p>
          Bak den er politimesteren. Du blir sjokkert. Han er den siste personen
          du ville ha forventet å se der. Politimesteren smiler og vinker med et
          tau rundt håndleddene og anklene. Han forklarer at han ble skutt med
          bedøvelsespil i Majorstukrysset, og deretter ble bortført av maskerte
          menn i ambulansen. Han fikk ikke med seg mye av det som skjedde rundt
          seg, og sammenlikner bedøvelsen med følelsen man får av blir kjørt i
          en vaskemaskin. Det neste han husker er at han våkner opp i dette
          rommet i en stol med en fallskjermutløser i hånda. &laquo;Den som står
          bak vet ikke at de har messet med en stor fisk! Jeg er
          politimesteren!&raquo; Han kunne like gjerne ha vært Dalai Lama uten
          at det ville ha utgjort en forskjell for de som står bak, tenker du.
        </p>

        <p>
          Du hjelper politimesteren løs fra stolen, og tar fallskjermutløseren i
          bukselomma. Rommet dere er i er hverken spesielt bredt eller spesielt
          dypt, men det er voldsomt høyt. Du ser opp, men det er umulig å se
          taket. Alikevel er det som er mest slående med det rommet (etter at
          man har kommet over det faktum av politimesteren er her) at det er det
          som ser ut som tusenvis på tusenvis av innpakkede gaver som ligger i
          en haug. Harde pakker. Du og politimesteren bestemmer dere for å komme
          dere unna så fort som mulig. Dere begynner å gå tilbake der du kom
          fra, men oppdager raskt at gangen rett og slett er for trang for den
          tykke politimesteren.
        </p>

        <p>&laquo;Kan du ikke bare presse deg igjennom?&raquo;</p>

        <p>Han stryker seg i skjegget.</p>

        <p>&laquo;Hm, dette fungerer tydeligvis bare vertikalt&raquo;</p>

        <p>
          &laquo;Vi kan prøve å stable pakker for å klatre ut? Eller jeg kan dra
          tilbake for å hente hjelp?&laquo;
        </p>
      </div>

      <div className="tagline">Hva gjør du nå?!</div>
      <ChoiceForm
        day="9"
        choices={[
          "Prøver å dra tilbake igjennom tunnellen alene",
          "Stabler pakker med politimesteren for å klatre ut",
          "Spør om politimesteren har en røyk du kan bomme"
        ]}
      />

      <TomorrowBox
        questions={[
          "Hvem står bak bortføringen av politimesteren?",
          "Hvorfor har Canasta en hemmelig gang som leder til dette rommet?",
          "Hvem er Aksel Sintra?"
        ]}
      />
    </div>
  );
}
