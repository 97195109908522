import React, { useState, useEffect, useContext } from "react";
import { TomorrowBox } from "./TomorrowBox";
import { Store } from "./App";
import { DotBreak } from "./DotBreak";

function AnagramSolver(props: {
  start: string;
  end: string;
  onChange: (guess: string, taken: string, isSolved: boolean) => void;
  initialGuess: string;
  initialTaken: string;
}) {
  const [guess, setGuess] = useState(props.initialGuess || "");
  const [taken, setTaken] = useState(props.initialTaken || props.start);
  useEffect(() => {
    props.onChange(guess, taken, guess === props.end);
  }, [guess]);
  return (
    <div>
      <p>Sul Canasta er:</p>
      <div
        style={{
          display: "flex",
          marginTop: "32px",
          marginBottom: "16px"
        }}
      >
        {props.start.split("").map((letter, i) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "64px",
              height: "64px",
              fontSize: "24px",
              borderRadius: "4px",
              fontWeight: "bold",
              fontFamily: "Patrick Hand SC",
              marginRight: "8px",
              background: taken[i] === "_" ? "#eee" : "#aee371",
              color: taken[i] === "_" ? "#ccc" : "white",
              textShadow:
                taken[i] === "_" ? "2px 2px 0 #aaa" : "2px 2px 0 black",
              boxShadow:
                taken[i] === "_" ? "4px 4px 0 #ddd" : "4px 4px 0px #75a142",
              cursor: "pointer"
            }}
            onClick={e => {
              e.preventDefault();
              if (taken[i] === "_") {
                return;
              }
              setTaken(taken.slice(0, i) + "_" + taken.slice(i + 1));
              let guessIndex = guess
                .split("")
                .findIndex(letter => letter === " ");
              if (guessIndex === -1) {
                guessIndex = guess.length;
              }
              setGuess(
                guess.slice(0, guessIndex) +
                  props.start[i] +
                  guess.slice(guessIndex + 1)
              );
            }}
          >
            {letter}
          </div>
        ))}
      </div>

      <div
        style={{
          display: "flex"
        }}
      >
        {props.start.split("").map((_, i) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "64px",
              height: "64px",
              fontSize: "24px",
              borderRadius: "4px",
              fontWeight: "bold",
              fontFamily: "Patrick Hand SC",
              marginRight: "8px",
              background:
                guess[i] === props.end[i]
                  ? "#ffc800"
                  : (guess[i] || "").trim()
                  ? "#888"
                  : "#eee",
              color: guess[i] === props.end[i] ? "black" : "white",
              textShadow:
                guess[i] === props.end[i]
                  ? "2px 2px 0 #ffeeb3"
                  : "2px 2px black",
              boxShadow:
                "4px 4px 0px " +
                (guess[i] === props.end[i]
                  ? "#b59319"
                  : (guess[i] || "").trim()
                  ? "#666"
                  : "#ddd"),
              cursor: "pointer"
            }}
            onClick={e => {
              e.preventDefault();
              if (props.end === guess) {
                return;
              }
              const originalIndex = props.start
                .split("")
                .findIndex(
                  (letter, j) => letter === guess[i] && taken[j] === "_"
                );
              setTaken(
                taken.slice(0, originalIndex) +
                  guess[i] +
                  taken.slice(originalIndex + 1)
              );
              setGuess(guess.slice(0, i) + " " + guess.slice(i + 1));
            }}
          >
            {guess[i]}
          </div>
        ))}
      </div>
    </div>
  );
}

export function Day14() {
  const store = useContext(Store);
  const [isSolved, setIsSolved] = useState(false);
  return (
    <div className="day-wrapper day-14">
      <div className="content-card">
        <h2>Kapittel 14</h2>

        {store.choices && (
          <AnagramSolver
            start="SULCANASTA"
            end="SANTACLAUS"
            initialGuess={(store.choices["14"] || "|").split("|")[0]}
            initialTaken={(store.choices["14"] || "|").split("|")[1]}
            onChange={(guess, initialTaken, newIsSolved) => {
              setIsSolved(newIsSolved);
              store.select("14", guess + "|" + initialTaken);
            }}
          />
        )}

        {isSolved && (
          <>
            <p>Santa Claus!</p>

            <p>
              Alle gavene. Skjegget. Den rotunde kroppsformen. Magisk nisselue.
              Sul Canasta er selveste Santa Claus! Og han virker sinna. Hva er
              det han planlegger? Det kan virke som at innbruddet i Valkyriegata
              13 var ett ledd i hans plan. En plan om å ta hevn. Men om hva?
            </p>

            <p>
              Det som ble stjålet må ha vært viktig for planen. Kan det ha vært
              nisselua? Som ser ut til å gi magiske nissekrefter? Hvorfor lå den
              nisselua hos gullsmeden i såfall? Og hvordan visste politimesteren
              om alt dette? Apropos politimesteren, nok en gang har han brått
              forsvunnet før han har rukket å forklare seg. Hvor har det blitt
              av ham? Har han også blitt sendt hjem til seg selv?
            </p>

            <DotBreak />

            <p>
              Du bestemmer deg for å lage en kopp kaffe mens du samler tankene.
              Hvorfor ikke. Maskinen du har hjemme er automatisk &mdash; du bare
              fyller på vann, legger i filteret, og lar maskinen gå sin gang
              (dog ikke uten å stille ned kaffebønnestyrken akkurat ett hakk, da
              blir kaffen best). Du må finne ut hva du skal gjøre nå. Hvilke
              spor sitter du igjen med? Du finner fram en notatblokk og noterer.
              Etterhvert som du går igjennom ledetrådene stryker du ut de der du
              mangler informasjon til å gå videre:
            </p>

            <div
              style={{
                marginTop: "32px",
                borderRadius: "4px",
                overflow: "hidden",
                background: "#f5f5f5",
                boxShadow: "4px 4px 0 black",
                fontSize: "20px",
                fontFamily: "Patrick Hand SC"
              }}
            >
              {[
                " ",
                "Politimesteren: sporløst forsvunnet",
                "Santa Claus: sporløst forsvinnet",
                "Hva ble stjålet: nisselue",
                "Aksel Sintra: i Spania",
                " "
              ].map((line, i) => (
                <div
                  style={{
                    height: "32px",
                    display: "flex",
                    fontSize: "20px",
                    fontFamily: "Patrick Hand SC",
                    alignItems: "flex-end",
                    padding: "0",
                    borderBottom: "1px solid #e89090"
                  }}
                >
                  <div
                    style={{
                      alignSelf: "stretch",
                      borderRight: "1px solid #e89090",
                      marginLeft: "48px",
                      marginRight: "16px"
                    }}
                  />
                  {i !== 4 ? <del>{line}</del> : line}
                </div>
              ))}
            </div>

            <p>
              Det ser ut til at det er på tide å finne Aksel. Han er i Spania?
              Vel, det er i alle fall noe. Du tømmer i deg den nytraktede kaffen
              rett fra kolben og setter deg på første flytog til Gardermoen.
              Spania neste!
            </p>
          </>
        )}
      </div>

      {isSolved && (
        <TomorrowBox
          questions={[
            "Daaamn sick reveal, bro!",
            "Spania er stort, hvordan skal Myrte finne fram?",
            "Hva er Santa Claus sin plan?"
          ]}
        />
      )}
    </div>
  );
}
