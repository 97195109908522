import React, { useState, useEffect, useContext } from "react";
import { TomorrowBox } from "./TomorrowBox";
import { Store } from "./App";
import { DotBreak } from "./DotBreak";

function AnagramSolver(props: {
  start: string;
  end: string;
  onChange: (guess: string, taken: string, isSolved: boolean) => void;
  initialGuess: string;
  initialTaken: string;
}) {
  const [guess, setGuess] = useState(props.initialGuess || "");
  const [taken, setTaken] = useState(props.initialTaken || props.start);
  useEffect(() => {
    props.onChange(guess, taken, guess === props.end);
  }, [guess, props, taken]);
  return (
    <div>
      <p>Politimester Nils Jeneus er:</p>
      <div
        style={{
          display: "flex",
          marginTop: "32px",
          marginBottom: "16px"
        }}
      >
        {props.start.split("").map((letter, i) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "64px",
              height: "64px",
              fontSize: "24px",
              borderRadius: "4px",
              fontWeight: "bold",
              fontFamily: "Patrick Hand SC",
              marginRight: "8px",
              background: taken[i] === "_" ? "#eee" : "#aee371",
              color: taken[i] === "_" ? "#ccc" : "white",
              textShadow:
                taken[i] === "_" ? "2px 2px 0 #aaa" : "2px 2px 0 black",
              boxShadow:
                taken[i] === "_" ? "4px 4px 0 #ddd" : "4px 4px 0px #75a142",
              cursor: "pointer"
            }}
            onClick={e => {
              e.preventDefault();
              if (taken[i] === "_") {
                return;
              }
              setTaken(taken.slice(0, i) + "_" + taken.slice(i + 1));
              let guessIndex = guess
                .split("")
                .findIndex(letter => letter === " ");
              if (guessIndex === -1) {
                guessIndex = guess.length;
              }
              setGuess(
                guess.slice(0, guessIndex) +
                  props.start[i] +
                  guess.slice(guessIndex + 1)
              );
            }}
          >
            {letter}
          </div>
        ))}
      </div>

      <div
        style={{
          display: "flex"
        }}
      >
        {props.start.split("").map((_, i) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "64px",
              height: "64px",
              fontSize: "24px",
              borderRadius: "4px",
              fontWeight: "bold",
              fontFamily: "Patrick Hand SC",
              marginRight: "8px",
              background:
                guess[i] === props.end[i]
                  ? "#ffc800"
                  : (guess[i] || "").trim()
                  ? "#888"
                  : "#eee",
              color: guess[i] === props.end[i] ? "black" : "white",
              textShadow:
                guess[i] === props.end[i]
                  ? "2px 2px 0 #ffeeb3"
                  : "2px 2px black",
              boxShadow:
                "4px 4px 0px " +
                (guess[i] === props.end[i]
                  ? "#b59319"
                  : (guess[i] || "").trim()
                  ? "#666"
                  : "#ddd"),
              cursor: "pointer"
            }}
            onClick={e => {
              e.preventDefault();
              if (props.end === guess) {
                return;
              }
              const originalIndex = props.start
                .split("")
                .findIndex(
                  (letter, j) => letter === guess[i] && taken[j] === "_"
                );
              setTaken(
                taken.slice(0, originalIndex) +
                  guess[i] +
                  taken.slice(originalIndex + 1)
              );
              setGuess(guess.slice(0, i) + " " + guess.slice(i + 1));
            }}
          >
            {guess[i]}
          </div>
        ))}
      </div>
    </div>
  );
}

export function Day21() {
  const store = useContext(Store);
  const [isSolved, setIsSolved] = useState(false);
  return (
    <div className="day-wrapper day-21">
      <div className="content-card">
        <h2>Kapittel 21</h2>

        <p>
          Sammen må dere få stoppet Santa Claus. Men hvordan? Han har sine
          kraftige magiske nissekrefter. Sinterklaas sine nissekrefter alene er
          kanskje ikke nok til å stoppe ham. I alle fall ikke i følge Canasta
          selv da du sist så ham. Men kanskje dere har flere magiske krefter på
          lager? Hvem er egentlig politimester Nils Jeneus?
        </p>

        {store.choices && (
          <AnagramSolver
            start="NILSJENEUS"
            end="JULENISSEN"
            initialGuess={(store.choices["21"] || "|").split("|")[0]}
            initialTaken={(store.choices["21"] || "|").split("|")[1]}
            onChange={(guess, initialTaken, newIsSolved) => {
              setIsSolved(newIsSolved);
              store.select("21", guess + "|" + initialTaken);
            }}
          />
        )}

        {isSolved && (
          <>
            <p>Julenissen!</p>

            <p>
              Dere har to nissekrefter på plass! Sammen har dere kanskje
              mulighet til å vinne over Canasta. Første steg blir å finne ut hva
              neste ledd i hans plan er. Det skal vise seg å være like lett
              gjort som sagt.
            </p>

            <img
              style={{
                marginTop: "32px",
                width: "100%",
                borderRadius: "4px"
              }}
              src="https://media.giphy.com/media/WWUUEUZl9tztC/giphy.gif"
            />

            <p>
              Plutselig kommer det et massivt drønn som får hele lokalt til å
              riste. Hva var det? Det kommer hyl fra utenfra. Dere spretter opp
              (politimesteren kjapper seg å betale for runden han spanderte på
              da han kom inn. Han stikker hånden inn i det store hvite skjegget
              hans og trekker ut et par tusen tomflasker så han får betalt med
              pant). Så løper dere ut.
            </p>

            <p>&laquo;Alle er slemme! Alle skal straffes!&raquo;</p>

            <p>
              Ropet ringer igjennom hele Bogstadveien. Til og med ned til der
              det bytter navn til Hegdehaugsveien. Da vet man at det er et høyt
              rop.
            </p>

            <p>&laquo;Dere skal betale for deres synder!&raquo;</p>

            <p>
              Sul Canasta flyr over gatene i en enorm slede av metall og slipper
              ned julegavebomber på folkene under. (De gavene ser kjente ut!).
            </p>

            <img
              alt=""
              src="present.jpg"
              style={{
                width: "33.33%",
                display: "block",
                margin: "64px auto"
              }}
            />

            <p>Dere springer i aksjon.</p>
          </>
        )}
      </div>

      {isSolved && (
        <TomorrowBox
          questions={[
            "Daaamn sick reveal (nummer 3), bro!",
            "Holy shit Santa Claus er jo helt crazy!",
            "Vil dere klare å stoppe ham?!"
          ]}
        />
      )}
    </div>
  );
}
