import React from "react";
import { TomorrowBox } from "./TomorrowBox";

export function Day11() {
  return (
    <div className="day-wrapper day-11">
      <div className="content-card">
        <h2>Kapittel 11</h2>
        <p>
          Døren åpner inn i et godt belyst rom med mørke møbler og bokhyller
          langs veggene. Det er høyt under taket. Det er en dør på den andre
          siden av rommet som ser ut til å gå videre inn i bygget. Gulvplassen
          preges av et dominerende skrivebord med doble skuffer. Det flyter over
          med lange papirremser med tabulert håndskrift. Du plukker opp en rems
          på måfå og prøver å lese. Håndskriften er nærmest uforståelig.
          Politimesteren, derimot, ser ut til å ha fått noe ut av det.
        </p>
        <p>&laquo;Nei, nei, nei! Dette er helt feil!&raquo;</p>
        <p>Du ser bort på ham.</p>
        <p>
          &laquo;En katastrofe! Gale konklusjoner trukket på utdaterte premisser
          og et ikke-eksisterende faktagrunnlag!&raquo;
        </p>
        <p>&laquo;Hva er det?&raquo;, spør du i det du peker på papiret.</p>
        <p>&laquo;Dette er listene hans.&raquo;</p>
        <p>&laquo;Lister?&raquo;</p>
        <p>
          Før politimesteren rekker å svare slenges den andre døren opp med et
          kraftig brak.
        </p>
        <p>
          &laquo;Hva er dette?! Hvem er dere? Hva holder dere på med?&raquo;
        </p>
        <p>
          En kraftig kar med hvitt hår, skjegg og solbriller. Han spytter når
          han roper. Ikke direkte tjukk, men ikke langt unna heller. Hadde
          situasjonen vært en annen kunne man kanskje ha sagt at han likner litt
          på politimesteren, i grunnen.
        </p>

        <p>&laquo;Spytt ut!&raquo;</p>

        <p>
          Du klarer ikke unngå å legge merke til dualiteten i den setningen.
          Politimesteren retter seg opp og snur seg mot mannen som nettopp har
          brast inn i rommet.
        </p>

        <p>&laquo;Sul Canasta. Lenge siden sist.&raquo;</p>
      </div>

      <TomorrowBox
        questions={[
          "Hvordan kjenner politimesteren og Sul hverandre?",
          "Hva var det politimesteren så som gjorde han så oppgitt?",
          "Når skal politimesteren rekke å utdype hva han snakket om da han nevnte Aksel Sintra i kapittel 2?"
        ]}
      />
    </div>
  );
}
