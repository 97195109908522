import React from "react";
import { TomorrowBox } from "./TomorrowBox";
import { DotBreak } from "./DotBreak";

export function Day23() {
  return (
    <div className="day-wrapper day-23">
      <div className="content-card" style={{ marginTop: "32px" }}>
        <h2>Kapittel 23</h2>
        <p>
          <em>&laquo;Til: Myrte Vest. Fra: Santa Claus&raquo;.</em> Øynene dine
          klarer ikke å unngå å lese hva som står på gavelappen som er festet
          til julegavebomben du holder i hendene i det du suser mot bakken.
          Heller det enn å tenke på hva som kommer til å skje om et sekund eller
          to. Asfalten under deg suser oppover i en farlig fart. Du rekker ikke
          begynne veie opp om det er kontakten med bakken eller detonasjonen av
          eksplosivene du holder i hånda som kommer til å ta deg først &mdash;
          den tanken blir avbrutt før den en gang rekker å starte.
        </p>

        <p>&laquo;MYRTE!&raquo;</p>

        <p>
          Politimesteren har fått nyss i hva som skjer. Har han et ess i ermet?
        </p>

        <p>&laquo;AKSEL!&raquo;</p>

        <p>
          Han retter øynene mot Aksel med en konsentrasjon få har sett maken
          til. Aksel møter blikket og nikker. De vet begge hva som må skje nå.
          De hadde håpet de ikke måtte ty til dette, men nå har de ikke lenger
          noe valg. De begraver begge hendene sine i sine respektive skjegg og
          graver dypt. Begevelsene skjer synkront, som om det var øvd inn.
          Hendene går dypere og dypere inn, helt til en ny hånd kravler seg ut
          og hytter spent, hengende ut fra det samme skjegget. Julenissen,
          Sinterklaas og Santa Claus sine skjegg er på noe vis koblet sammen med
          nissekraft &mdash; det er Julenissens venstre arm som stikker ut av
          Sinterklaas sitt skjegg, og Sinterklaas sin høyre arm som stikker ut
          fra skjegget til Julenissen. De to resterende armene har funnet veien
          inn til og ut av skjegget til Santa Claus.
        </p>

        <p>
          Armene som stikker ut av skjeggene tar tak i sine skjeggs respektive
          skuldre, og i en grasiøst koreografert simultanbevegelse trekker
          kroppene skuldre-først igjennom skjeggene. Dette skjer samtidig hos
          alle tre. Resultatet er en logisk umulighet og en magisk implosjon der
          de tre forsvinner inn i intet. Kraftvakumet som henger igjen
          kondenserer øyeblikkelig til en høykomprimert sky av julesnø, som
          momentanekspanderer ut i gatene opp til 3. etasje i flere kvartalers
          omkrets. Frogner er på sekundet fylt med rekordmenger snø. Du lander
          fra ditt frifall rett inn i denne store, pudrete snømassen, som bryter
          fallet. Trygg. Julegavebomben er imidlertid fortsatt i hendene dine.
          Utrygg.
        </p>

        <p>
          &laquo;Jeg vil ikke dø! Jeg vil hjem! Jeg vil ikke dø! Jeg vil
          hjem!&raquo;
        </p>

        <p>
          Dette er alt du rekker å tenke der du sitter, med snø opp over hoftene
          og en julegavebombe i hendene. Med nisse-trioen borte er det ingenting
          som kan redde deg.
        </p>

        <p>Bortsett fra en ny nisse.</p>

        <p>
          Den magiske nisselua til Santa Claus forsvant ikke da de tre nissene
          tok seg selv av dage. Den falt ut av sleden under oppstyret og koste
          seg med en rolig tyngdekraftsstyrt nedfart mot bakken under, der du
          tilfeldigvis sitter fast. Nå lander den pyntelig og pent på hodet
          ditt, og den begynner øyeblikkelig å adlyde sin nye mesters ordre.{" "}
          <em>&laquo;Jeg vil hjem!&raquo;</em>
        </p>

        <p>
          Julegavebomben eksploderer endelig, men du finner seg selv for andre
          gang på kort tid teleportert tilbake sin egen leilighet. Drønnet fra
          julegavebomben hører du i det fjerne.
        </p>

        <DotBreak />

        <div
          style={{
            marginTop: "32px",
            borderRadius: "4px",
            overflow: "hidden",
            background: "#f5f5f5",
            boxShadow: "4px 4px 0 black",
            fontSize: "20px",
            fontFamily: "Patrick Hand SC"
          }}
        >
          {[
            " ",
            <>
              <div>Sul Canasta</div>
              <div>Eliminert &nbsp;☑️</div>
            </>,
            <>
              <div>Aksel Sintra</div>
              <div>Eliminert &nbsp;☑️</div>
            </>,
            <>
              <div>Nils Jeneus</div>
              <div>Eliminert &nbsp;☑️</div>
            </>,
            " "
          ].map((line, i) => (
            <div
              style={{
                height: "32px",
                display: "flex",
                fontSize: "20px",
                fontFamily: "Patrick Hand SC",
                alignItems: "flex-end",
                padding: "0 16px 0 64px",
                borderBottom: "1px solid #e89090",
                justifyContent: "space-between",
                position: "relative"
              }}
            >
              <div
                style={{
                  position: "absolute",
                  alignSelf: "stretch",
                  borderRight: "1px solid #e89090",
                  left: "48px",
                  width: 0,
                  top: 0,
                  bottom: 0
                }}
              />
              {line}
            </div>
          ))}
        </div>

        <DotBreak />

        <p>
          Du blander deg en stiv drink, tenner en sigarett, setter deg ned i
          godstolen og lener deg tilbake. Et stort smil brer seg utover ansiktet
          ditt. Alt gikk etter planen.
        </p>
      </div>

      <TomorrowBox
        questions={[
          "De tre nissene er døde!",
          "Hvem skal levere gaver nå?",
          "Hvordan kan Myrte virke så fornøyd?!"
        ]}
      />
    </div>
  );
}
