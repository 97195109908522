import React from "react";
import { TomorrowBox } from "./TomorrowBox";

export function Day5() {
  return (
    <div className="day-wrapper day-5">
      <div className="content-card" style={{ marginTop: "32px" }}>
        <h2>Kapittel 5</h2>
        <p>
          &laquo;Uuhh mm hvordan er en vekter og en fyllik
          lik?...…...................... De er mennesker! Hahaha&raquo;
        </p>

        <p>
          Du forteller vitsen med munnen på skrå og løfter armene ut suggestivt
          mens du vrikker litt på skuldrene for å hjelp vakten dra i gang
          smilebåndet. &laquo;Ehh? Eehh?&raquo;
        </p>

        <p>
          Vakten bretten opp ermene og tar av seg brillene. Armbåndsuret løsnes
          og løftes av håndleddet, og han glider ned jakka og drar den av seg
          over skuldrene. Du skjønner at her kommer det til å bli bråk.
          Pannebåndet kommer av. Skolissene løsnes og buksebena brettes opp.
          Vakten trekker i håret og river ut store klumper som går i bakken. Han
          river av seg i et forrykende tempo. Han griper tak i ansiktet med
          begge hendene og river det bent av. Det tyter ut... blod? Nei, det ser
          mer ut som teaterlim eller noe sånt. Ansiktet i hendene trekker med
          seg huden på skuldrene og nedover armene, som nå vrenger seg selv
          rundt håndleddet. Prosessen spruter. Du kommer til å trenge en ny
          jakke når alt dette er over. Vakten har nå vokst til å bli 3 meter
          høy, og dobbelt så tykk, og er dessuten dekt av en grønn slimete
          øglehud, har fått tre ekstra bein og stå på, og enorme
          øyenstikkervinger festet til det som pleide å være hofta (men som nå
          ser ut til å være en slags smultring av bein med en sirkelsag i
          midten). Han slipper ut et gedigent brøl. Den må de ha hørt helt bort
          på Smestad, tenker du.
        </p>

        <div
          style={{ position: "relative", marginBottom: "64px" }}
          className="crazyshake"
        >
          <p
            style={{
              fontFamily: "arial",
              position: "absolute",
              fontSize: "32px",
              marginTop: "-4px",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              transform: "rotate(-0.5deg)",
              color: "red",
              opacity: 0.5
            }}
          >
            &laquo;Den vitsen traff ikke helt fordi jeg er ikke et menneske!
            Hahaha hahahahaha!&raquo;
          </p>
          <p
            style={{
              fontFamily: "arial",
              position: "absolute",
              fontSize: "32px",
              marginTop: "4px",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              transform: "rotate(2.5deg)",
              color: "#0ff",
              opacity: 0.5
            }}
          >
            &laquo;Den vitsen traff ikke helt fordi jeg er ikke et menneske!
            Hahaha hahahahaha!&raquo;
          </p>
          <p
            style={{
              fontSize: "32px",
              transform: "rotate(-1deg)",
              textShadow: "0 0 2px black"
            }}
          >
            &laquo;Den vitsen traff ikke helt fordi jeg er ikke et menneske!
            Hahaha hahahahaha!&raquo;
          </p>
        </div>

        <p>
          Vakt-monsteret slår deg i ansiktet. Slaget er hardt, og hodet ditt
          faller av. Du er død.
        </p>

        <img
          src="rip.png"
          alt="rip"
          style={{ width: "128px", margin: "64px auto", display: "block" }}
        />

        <div
          className="inventory"
          style={{ marginTop: "16px", marginBottom: "64px" }}
        >
          <div className="inventory-label">Helsetilstand</div>
          <div style={{ marginTop: "16px", minWidth: "200px" }}>Død.</div>
        </div>

        <div className="click-reveal" style={{ textAlign: "center" }}>
          <button disabled className="btn btn-primary">
            Hæ?
          </button>

          <div>
            <div className="text">Du er død.</div>

            <button disabled className="btn btn-primary">
              Serr?
            </button>

            <div>
              <div className="text">Jepp. Du ble drept.</div>

              <button disabled className="btn btn-primary">
                Okei?
              </button>

              <div>
                <div className="text">Sånn kan det gå.</div>

                <button disabled className="btn btn-primary">
                  Lol?
                </button>

                <div>
                  <div className="text">Hehe.</div>

                  <button disabled className="btn btn-primary">
                    Er det tull?
                  </button>
                  <div>
                    <div className="text">Jaok ja det er tull.</div>

                    <button disabled className="btn btn-primary">
                      Hva skal jeg gjøre nå da?
                    </button>

                    <div>
                      <div className="text">
                        Vi ruller tilbake tiden, tenker jeg.
                      </div>

                      <button disabled className="btn btn-primary">
                        Rull tilbake tiden.
                      </button>

                      <div>
                        <div className="text" style={{ textAlign: "left" }}>
                          Tiden er nå rullet tilbake. Vi prøver oss på nytt med
                          den vitsen.
                        </div>

                        <hr />

                        <div className="text" style={{ textAlign: "left" }}>
                          <p>
                            «Uuhh mm hvordan er en vekter og en fyllik
                            lik?...…...................... De er mennesker!
                            Hahaha»
                          </p>
                          <p>
                            «Hehe. Hehehe. Hehehehehehehe. Artig. Jeg liker deg.
                            Bare gå inn du.&raquo;
                          </p>
                          <p>
                            Vakten går til side og slipper deg inn. På vei inn
                            merker du at du har noe grønt slim på den ene sida
                            av jakka som du ikke skjønner hvor kommer fra.
                            Samtidig har du plutselig litt vondt i nakken. Du
                            tenker ikke mer på det. På anlegget spiller de
                            Sirkel Sag, men du er mer interessert i personen du
                            treffer i baren.
                          </p>

                          <TomorrowBox
                            style={{
                              boxShadow: "none",
                              margin: "32px -32px -32px -32px"
                            }}
                            questions={[
                              "Hvem treffer Myrte i baren?",
                              "WTF finnes det aliens?",
                              "Nei sånn serr, hva var det for noe?"
                            ]}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
