import React from "react";
import { ChoiceForm } from "./ChoiceForm";
import { TomorrowBox } from "./TomorrowBox";

export function Day1() {
  return (
    <div className="day-wrapper day-1">
      <div className="content-card">
        <h2>Innbruddet i Valkyriagata 13</h2>
        <p>
          Frostrøyken glinser i blålysene. Det har blitt kaldt. Du krysser
          politisperringen, kaffekoppen i hånda, og skuer utover åstedet.
        </p>

        <p>«Detektiv Vest! Der er du!»</p>

        <p>
          Du snur deg og får øye på politimesteren selv, som åpenbart har tatt
          turen. Uvanlig. Politimesteren er litt på den lubne siden, og det er
          mange år siden han selv var operativ i felten. Mange tuller med at det
          lange hvite skjegget hans har grodd fast i pulten. Det stemmer jo
          ikke, selvfølgelig, men alle rykter har jo et snev av sannhet, tenker
          du. Han fortsetter:
        </p>

        <p>
          «Dette er ikke noen vanlig sak, Myrte. Det kan kanskje se ut som et
          vanlig førjulsinnbrudd hos gullsmeden her, men sannheten er at det er
          noe mye mer verdifullt enn gull og diamanter som har blitt stjålet her
          i kveld.»
        </p>

        <p>
          «Hva er det han snakker om?» tenker du til deg selv. Det var jo et
          amatørmessig nattebrekk. Alarmen gikk av i det tyvene knuste
          utstillingsvinduet for å ta seg inn, og de to mistenkte ble arrestert
          utenfor for et kvarter siden. De hadde forsøkt å komme seg avgårde på
          bysykkel, men hadde fått tekniske problemer da de prøvde å løse ut to
          sykler på samme telefonnummer. Politimesteren lener seg inn og slår
          over til en mer hemmelihetsfull tone:
        </p>

        <p>
          «Denne gullsmeden var nemlig ingen vanlig gullsmed. Den har blitt
          utvalgt til noe helt spesielt. Den har blitt valgt ut til å beskytte
          og passe på verdens største…»
        </p>

        <img
          src="pwo.jpg"
          style={{
            width: "25%",
            display: "block",
            margin: "32px auto 0"
          }}
        />

        <p>
          Det smeller brått og politimesteren griper seg til halsen. Øynene hans
          ser ut som to grønne druer i det pupillene trekker seg sammen til små
          blyantspisser. Forskrekket i ansiktet faller politimesteren sammen
          mens han hiver etter pusten. I utkanten av synsfeltet ser du omrisset
          av en skyggete skikkelse kaste seg inn i en taxi. Det som skulle være
          den rolige siste dagen på jobb før juleferiens mak senket seg ble
          plutselig alt annet enn rolig.
        </p>
      </div>

      <div className="tagline">Hva gjør du nå?!</div>

      <ChoiceForm
        day="1"
        choices={[
          "Kaster deg på en bysykkel og følger skikkelsen i taxien",
          "Ringer 113, sjefen har jo fått et eller annet anfall",
          "Drikker opp kaffen først, før du tar deg en røyk og tar deg inn igjennom det knuste vinduet til gullsmeden"
        ]}
      />

      <TomorrowBox
        questions={[
          "Hva skjer med politimesteren?",
          "Hva er det som er så spesielt med denne gullsmedforretningen?",
          "Er bysykkel greit når det er så glatt ute?"
        ]}
      />
    </div>
  );
}
