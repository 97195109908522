import React from "react";
import { TomorrowBox } from "./TomorrowBox";

export function Day12() {
  return (
    <div className="day-wrapper day-12">
      <div className="content-card">
        <h2>Kapittel 12</h2>
        <p>
          Nå som vi er halvveis er det på tide med innslag fra våre sponsorer!
        </p>

        <img
          src="stolas.png"
          alt=""
          style={{
            marginTop: "32px",
            marginBottom: "0px",
            width: "100%",
            borderRadius: "4px"
          }}
        />

        <p>
          Denne serien hadde ikke vært mulig uten vår kjære førstesponsor{" "}
          <strong>Stol AS</strong>. Enten du trenger en stor krakk eller en
          liten lenestol, eller hvorfor ikke noe i mellom? Stol AS har det du
          trenger på lager. Stoler med tre bein, stoler med fire bein. Stoler
          med 5 bein. Alt kan du få fra Stol AS! Stol AS lanserer nå en ny serie
          med ekstra behagelige stoler med puter på. De er av vannavstøtende,
          frastøtende og beskytter mot støtene (statisk elektrisitet). Har du
          behov for en elektrisk stol selger vi det også.
        </p>

        <p>
          Stol AS &mdash; <em>&laquo;Stol på oss!&raquo;</em>
        </p>

        <hr style={{ marginTop: "64px", marginBottom: 0 }} />

        <img
          src="lutas.png"
          alt=""
          style={{
            marginTop: "32px",
            marginBottom: "0px",
            width: "100%",
            borderRadius: "4px"
          }}
        />

        <p>
          I tillegg ønsker vi å takke vår andresponsor <strong>Lut AS</strong>.
          Du kan tillate deg å feste lit til Lut AS om du trenger lut, lit, lot,
          lake, lik, luker, lutefisk eller ellers er lat. Litt av hvert kan Lut
          få ut. En som lot seg lute er Linda Lottesen: &laquo;Jeg liker Lut AS
          og lar luten bli levert hver lørdag,&raquo; sier Linda. Hun har ikke
          gått lut lei, for å si det sånn.
        </p>

        <p>
          Lut AS &mdash; <em>&laquo;Ha tillit til lut!&raquo;</em>
        </p>

        <hr style={{ marginTop: "64px", marginBottom: "64px" }} />

        <p>Like & subscribe!</p>
      </div>

      <TomorrowBox
        questions={[
          "Hvordan kjenner politimesteren og Sul hverandre?",
          "Hva var det politimesteren så som gjorde han så oppgitt?",
          "Når skal politimesteren rekke å utdype hva han snakket om da han nevnte Aksel Sintra i kapittel 2?"
        ]}
      />
    </div>
  );
}
