import React, { useRef, useEffect, useContext } from "react";
import { bootstrapGame, TrainGameClass } from "./game";
import { Store } from "./App";

interface Props {
  onComplete: (points: number) => void;
}

export function TrainGame(props: Props) {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const store = useContext(Store);
  const haveChoicesLoaded = !!store.choices;
  useEffect(() => {
    if (canvasRef.current && haveChoicesLoaded) {
      bootstrapGame(
        canvasRef.current,
        TrainGameClass,
        props.onComplete,
        store.choices["15"]
      );
    }
    //eslint-disable-next-line
  }, [canvasRef.current, haveChoicesLoaded]);
  return <canvas ref={canvasRef} />;
}
