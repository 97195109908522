import React, { useState } from "react";
import { TomorrowBox } from "./TomorrowBox";

const dialogTree: any = {
  start: {
    sintra: (
      <p>
        &laquo;Hvordan var reisen? Jeg håper sjåføren min tok greit vare på
        deg.&raquo;
      </p>
    ),
    choices: {
      "Den var ok.": "start2",
      "Jeg er dyvåt, se da!": "start3",
      "Vel, jeg har akkurat lært at det finnes nisser og magiske nisseluer som kan teleportere folk, så jeg tenkte ikke så mye på selve turen.":
        "startfake"
    }
  },
  startfake: {
    sintra: (
      <>
        <p>Du sier det ikke høyt, men tenker det inni deg.</p>
        <p>&laquo;Den var ok.&raquo;</p>
        <p>&laquo;Fint. Vi har mye å snakke om.&raquo;</p>
      </>
    ),
    choices: {
      "Hvem er du?": "whoareyou",
      "Hva vil du?": "whatdoyouwant",
      "Hvordan kjenner du politimesteren i Oslo?": "howknowpolice"
    }
  },
  start2: {
    sintra: <p>&laquo;Fint. Vi har mye å snakke om.&raquo;</p>,
    choices: {
      "Hvem er du?": "whoareyou",
      "Hva vil du?": "whatdoyouwant",
      "Hvordan kjenner du politimesteren i Oslo?": "howknowpolice"
    }
  },
  start3: {
    sintra: (
      <>
        <p>
          &laquo;Ja, den motorsykkelen har ikke så mye beskyttelse mot vær og
          vind. Her, la meg.&raquo;
        </p>
        <p>
          Aksel stikker hånda si inn i det lange hvite skjegget hans og trekker
          ut en...
        </p>
      </>
    ),
    choices: {
      "Et håndkle! Skal jeg liksom tørke meg med det?": "dryingtowel",
      "En hårføner! Skal jeg liksom tørke meg med det?": "dryinghair",
      "Ei brødskive! Skal jeg liksom tørke meg med det?": "dryingbread"
    }
  },
  whatdoyouwant: {
    sintra: (
      <>
        <p>
          &laquo;Hehe, alltid skal dere Nordmenn være så rett på sak. La gå. Jeg
          vil intet ringere enn å redde verden.&raquo;
        </p>
      </>
    ),
    choices: {
      "Hvordan kjenner du politimesteren i Oslo?": "howknowpolice"
    }
  },
  whoareyou: {
    sintra: (
      <>
        <p>
          &laquo;Hehe, alltid skal dere Nordmenn være så rett på sak. Jeg er
          Aksel Sintra.&raquo;
        </p>
        <p>
          Aksel stryker seg i skjegget. En brødskive faller ut. Han begynner å
          spise den. Du har ikke tid til å tenke på det nå.
        </p>
      </>
    ),
    choices: {
      "Hvordan kjenner du politimesteren i Oslo?": "howknowpolice"
    }
  },
  dryinghair: {
    sintra: (
      <>
        <p>
          Du plugger inn hårføneren og begynner å tørke av deg. Skoene kommer av
          og du begynner med å tørke sokkene.
        </p>
        <p>
          &laquo;Fint.&raquo; Aksel må rope litt for å overdøve hårføneren.
          &laquo;Vi har mye å snakke om.&raquo;
        </p>
      </>
    ),
    choices: {
      "Hvordan kjenner du politimesteren i Oslo?": "howknowpolice"
    }
  },
  dryingtowel: {
    sintra: (
      <>
        <p>
          En håndduk er bedre enn ingenting, tenker du. Du tørker deg i ansiktet
          og begynner å frottere håret. Det hjelper ingenting. Du er fortsatt
          like våt.
        </p>
        <p>&laquo;Okei.&raquo;</p>
      </>
    ),
    choices: {
      "Hvordan kjenner du politimesteren i Oslo?": "howknowpolice"
    }
  },
  dryingbread: {
    sintra: (
      <>
        <p>&laquo;Ehm nei, du har rett, det var et tåpelig forslag.&raquo;</p>
        <p>
          Aksel legger brødskiva tilbake i skjegget (lagrer han den til
          senere?).
        </p>
      </>
    ),
    choices: {
      "Hvordan kjenner du politimesteren i Oslo?": "howknowpolice"
    }
  },
  howknowpolice: {
    sintra: (
      <p>
        &laquo;Politimester Jeneus og jeg har kjent hverandre i mange år. Sul
        også, for den saks skyld. For mange år siden jobbet vi sammen.{" "}
        <em>Distribusjon av varer</em>. Men så oppstod det uenigheter om hvordan
        vi skulle bestemme hvem som skulle få hvilke varer. Sul ville være mye
        strengere, og hadde begynt å foreslå ekstremistiske metoder. Nils
        &mdash; politimesteren altså &mdash; og jeg fant ut at vi skulle gå bak
        Suls rygg og sabotere vår egen operasjon.&raquo;
      </p>
    ),
    choices: {
      "Hva gjorde dere?": "sabotage"
    }
  },
  sabotage: {
    sintra: (
      <>
        <p>
          &laquo;Nils og jeg stakk av med alt det viktigste utstyret vi brukte i
          jobben og fikk det oppbevart hos en pålitelig tredjepart.&raquo;
        </p>
        <p>Kan det være gullsmeden i Valkyriegata 13 det er snakk om?</p>
      </>
    ),
    choices: {
      "Det utsyret det er snakk om. Var det... en nisselue?": "sabotage2"
    }
  },
  sabotage2: {
    sintra: (
      <>
        <p>Sintra nikket.</p>
        <p>&laquo;Så du vet det. Det er riktig.&raquo;</p>
      </>
    ),
    choices: {
      "Sakte men sikkert begynner jeg å forstå...": "sabotage3"
    }
  },
  sabotage3: {
    sintra: <p>Aksel smiler.</p>,
    choices: {
      "Aksel Sintra...": "sabotage4"
    }
  },
  sabotage4: {
    sintra: (
      <>
        <p>
          Aksel reiser seg. &laquo;Da skjønner du hvor viktig det er at vi
          jobber sammen!&raquo;
        </p>
      </>
    )
  }
};

interface DialogEntry {
  content: any;
}

export function Day18() {
  const [currentStep, setCurrentStep] = useState("start");
  const [seenSteps, setSeenSteps] = useState({});
  const [dialogHistory, setDialogHistory] = useState<DialogEntry[]>([]);

  const nextSteps = dialogTree[currentStep].choices
    ? Object.entries(dialogTree[currentStep].choices).filter(
        //@ts-ignore
        ([text, target]) => !(target in seenSteps)
      )
    : [];

  function reset() {
    setCurrentStep("start");
    setSeenSteps({});
    setDialogHistory([]);
  }

  function doStep(line: string, target: string) {
    setDialogHistory([
      ...dialogHistory,
      { content: dialogTree[currentStep].sintra },
      { content: <p>&laquo;{line}&raquo;</p> }
    ]);
    setCurrentStep(target);
    setSeenSteps({ ...seenSteps, [target]: true });
  }

  return (
    <div className="day-wrapper day-18">
      <div className="content-card" style={{ marginTop: "32px" }}>
        <h2>Kapittel 18</h2>

        <p>
          Sintra. Han vet åpenbart hvem du er. Men hvem er han, egentlig?
          Politimesterens tilsynelatende siste ord i det han ble angrepet på
          Majorstua var <em>&laquo;Oppsøk... Aksel... Sintra...!&raquo;</em>{" "}
          Sist du så Canasta proklamerte han{" "}
          <em>&laquo;Aksel er maktesløs!&raquo;</em>. Og at han sitter fast i
          Spania. Vel, her er han. Og du. I Spania.
        </p>

        <p>&laquo;Jeg har ventet. Kom inn.&raquo;</p>

        <p>
          Aksel snakker Norsk med en kjent aksent. Han veiver deg inn med
          hendene og forsvinner inn i korridoren. Du følger etter. Det ser
          mindre ut på innsiden enn på utsiden. Veggene er kledd i falmgul tapet
          med floralmotiv. Stedet har en merkelig aura. Nesten som om det
          eksisterer i en egen tid, frakoblet fra resten av verden. Korridoren
          åpner ut i et lite værelse med et bord og noen stoler. Aksel setter
          seg, og du følger hans eksempel. En klokke henger på veggen, men den
          ser ikke ut til å gå riktig. Minuttviseren svinger rundt i en
          forrykende fart. Du skal til å kommentere på det løpske uret, men lar
          være. Et eller annet stikker i neglene dine. Du ser ned. Lange klør.
          Hadde du ikke nettopp klipt de? Her er det noe snodig på gang.
        </p>

        <div>
          {dialogHistory.map((entry, i) => (
            <>
              {i === dialogHistory.length - 1 && <hr className="mt-5" />}
              {entry.content}
            </>
          ))}
        </div>

        {dialogTree[currentStep].sintra}

        {!("dryinghair" in seenSteps) && (
          <>
            {dialogHistory.length > 2 && (
              <div className="mt-5 alert alert-info">
                🦠 Du begynner å hoste fælt.
              </div>
            )}
            {dialogHistory.length > 4 && (
              <div className="alert alert-warning">
                🦠🤧 Du begynner å gurgle slim.
              </div>
            )}
            {dialogHistory.length > 6 && (
              <div className="alert alert-danger">🦠🤧🤒 Feber slår inn.</div>
            )}
          </>
        )}
        {!("dryinghair" in seenSteps) && dialogHistory.length > 8 ? (
          <>
            <div className="alert alert-danger bg-dark text-light">
              ☠️ Du har dødd av akutt tuberkulose. ☠️
            </div>
            <div className="mt-5 d-flex align-items-center justify-content-center">
              <button className="btn btn-warning" onClick={reset}>
                Nullstill
              </button>
            </div>
          </>
        ) : (
          <>
            <p className="mt-5">
              <ol>
                {nextSteps.map(([text, target]) => (
                  <li className="mb-3">
                    <button
                      onClick={e => {
                        e.preventDefault();
                        doStep(text, target as string);
                      }}
                      className="text-left btn btn-primary"
                    >
                      {text}
                    </button>
                  </li>
                ))}
              </ol>
            </p>
          </>
        )}
      </div>

      <TomorrowBox
        questions={[
          "Er det riktig at huset til Aksel Sintra er i et slags tidsframskyndende felt, og at hovedgrunnen til dette er for å kunne drive tuberkulosesykdomsprogresjon ekstremt raskt hvis man er utsatt for smitte? (Og at dette er drevet av fortelleren, og ikke Aksel selv?)"
        ]}
      />
    </div>
  );
}
