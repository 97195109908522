import React from 'react';

export function Day0() {
  return (
    <div className="day-wrapper day-0">
      <div className="content-card">
        <h2>Innbruddet i Valkyriagata 13</h2>
        <p>Starter 1. desember!</p>
      </div>
    </div>
  );
}
