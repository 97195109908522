import React from "react";

export function DotBreak() {
  return (
    <p
      style={{
        textAlign: "center",
        textIndent: 0
      }}
    >
      * * *
    </p>
  );
}
